const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className="flex flex-col items-center space-y-2">
      <h2
        className={`${
          isDanger ? "text-error" : "text-primary"
        } text-2xl font-bold`}
      >
        {value}
      </h2>
      <span
        className={`${
          isDanger ? "text-error" : "text-text-secondary"
        } text-sm`}
      >
        {type}
      </span>
    </div>
  );
};

export default DateTimeDisplay;
