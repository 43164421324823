import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { EyeIcon, EyeOffIcon } from "assets";

function FormRegister1({ nextStep, handleFormData, values }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const validationSchema = Yup.object().shape({
    companyEmail: Yup.string()
      .required("Email tidak boleh kosong")
      .email("Format email salah"),
    password: Yup.string()
      .required("Password baru tidak boleh kosong")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,}$/,
        "Password harus terdiri dari huruf besar, huruf kecil, angka dan simbol. Minimal 6 karakter atau lebih."
      ),
    passwordConfirmation: Yup.string()
      .required("Konfirmasi password tidak boleh kosong")
      .oneOf([Yup.ref("password"), null], "Konfirmasi password harus sama"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const submitFormData = () => {
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit(submitFormData)}>
      <div className="mt-8 flex flex-col space-y-4 text-sm">
        <div className="flex flex-col space-y-2">
          <label className="font-bold">Email</label>
          <input
            type="email"
            name="companyEmail"
            defaultValue={values.companyEmail}
            {...register("companyEmail")}
            className={`form-control ${
              errors.companyEmail ? "is-invalid" : ""
            }`}
            placeholder="Masukkan email perusahaan"
            autoComplete="email"
            onChange={handleFormData("companyEmail")}
          />
          <div className="mt-1 invalid-feedback">
            {errors.companyEmail?.message}
          </div>
        </div>
        <div className="flex flex-col space-y-2 relative">
          <label className="font-bold">Password</label>
          <input
            type={`${showPassword ? "text" : "password"}`}
            name="password"
            defaultValue={values.password}
            {...register("password")}
            className={`form-control pr-10 ${
              errors.password ? "is-invalid" : ""
            }`}
            placeholder="Masukkan password"
            autoComplete="new-password"
            onChange={handleFormData("password")}
          />
          <div
            className="absolute top-[34px] right-3 text-gray-400"
            onClick={() => setShowPassword((prevState) => !prevState)}
          >
            {showPassword ? <EyeOffIcon /> : <EyeIcon />}
          </div>
          {errors.password ? (
            <div className="invalid-feedback">{errors.password?.message}</div>
          ) : (
            <div className="pt-4"></div>
          )}
        </div>
        <div className="flex flex-col space-y-2 relative">
          <label className="font-bold">Ulangi Password</label>
          <input
            type={`${showPasswordConfirmation ? "text" : "password"}`}
            name="passwordConfirmation"
            defaultValue={values.passwordConfirmation}
            {...register("passwordConfirmation")}
            className={`form-control pr-10 ${
              errors.passwordConfirmation ? "is-invalid" : ""
            }`}
            placeholder="Masukkan konfirmasi password"
            autoComplete="new-password"
            onChange={handleFormData("passwordConfirmation")}
          />
          <div
            className="absolute top-[35%] right-3 text-gray-400"
            onClick={() => setShowPasswordConfirmation((prevState) => !prevState)}
          >
            {showPasswordConfirmation ? <EyeOffIcon /> : <EyeIcon />}
          </div>
          {errors.passwordConfirmation ? (
            <div className="invalid-feedback">
              {errors.passwordConfirmation?.message}
            </div>
          ) : (
            <div className="pt-4"></div>
          )}
        </div>
      </div>
      <div className="my-8 ml-4 text-right">
        <button className="bg-gradient w-1/2 py-[13px] rounded-xl text-sm text-white font-bold">
          Selanjutnya
        </button>
      </div>
    </form>
  );
}
export default FormRegister1;
