import { useState, useEffect } from "react"
import DataTable from "react-data-table-component";
import { format, parseISO } from "date-fns";
import { getHistoryFinance } from "services/profileService";
import { currency } from "utils";

function HistoryCard() {
  const [history, setHistory] = useState([]);
  const [limitPage, setLimitPage] = useState(10);
  const [totalRows, setTotalRows] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchHistory = async (page) => {
    setLoading(true);
    try {
      const response = await getHistoryFinance(page, limitPage);
      setHistory(response.data);
      setTotalRows(response.meta.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchHistory(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await getHistoryFinance(page, newPerPage);
      setHistory(response.data);
      setLimitPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory(1);
  }, []);

  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#E8E8E8",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };
  // custom columns
  const Debit = (row) => (
    <div className="font-bold text-red">
      {currency(row.debit)}
    </div>
  );
  const Credit = (row) => (
    <div className="font-bold">
      {currency(row.credit)}
    </div>
  );

  const columns = [
    {
      name: "Tanggal",
      selector: (row) => row.createdAt,
      wrap: true,
      cell: (row) => format(parseISO(row.createdAt), "d MMM y HH:mm"),
    },
    {
      name: "Keterangan",
      selector: (row) => row.remarks,
      wrap: true,
      grow: 2,
    },
    {
      name: "Debit",
      selector: (row) => row.debit,
      wrap: true,
      cell: (row) => <Debit {...row} />,
    },
    {
      name: "Credit",
      selector: (row) => row.credit,
      wrap: true,
      cell: (row) => <Credit {...row} />,
    },
  ];

  function TableComponent() {
    return (
      <DataTable
        columns={columns}
        data={history}
        noDataComponent="Tidak ada data riwayat transaksi"
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[10, 15, 20]}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        striped
      />
    );
  }

  return (
    <>
      <div className="mt-4 lg:mt-0 w-full lg:max-w-[787px] h-max p-4 flex flex-col space-y-4 bg-white lg:rounded-2xl shadow-main">
        <div className="flex items-center space-x-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 6H21"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 12H21"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 18H21"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 6H3.01"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 12H3.01"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 18H3.01"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="text-lg font-bold">Riwayat Transaksi</h3>
        </div>
        <hr />
        <div className="my-8 flex flex-col space-y-4">
          <p className="mb-2 text-sm">
            Penghasilan dari Penakita akan masuk ke saldo Anda pada tanggal 30
            setiap bulannya.
          </p>
          <div className="rounded">{TableComponent()}</div>
        </div>
      </div>
    </>
  );
}

export default HistoryCard;
