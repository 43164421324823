import { account } from "assets";
import { FaStar } from "react-icons/fa";

function ReviewCard({ review }) {
  function Star({ filled }) {
    return <FaStar color={filled ? "orange" : "lightgray"} />;
  }

  return (
    review && (
      <div className="flex space-x-4" key={review.id}>
        <img
          src={review.clientPicture ?? account}
          alt={review.clientName}
          className="w-6 h-6 rounded-2xl object-cover"
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = account;
          }}
        />
        <div className="flex flex-col space-y-2">
          <p className="font-bold">{review.clientName ?? "-"}</p>
          <span className="flex space-x-1">
            {[1, 2, 3, 4, 5].map((value) => (
              <Star key={value} filled={value <= review.rate} />
            ))}
          </span>

          <p className="pt-2 text-sm text-text-secondary">
            {review.description}
          </p>
        </div>
      </div>
    )
  );
}

export default ReviewCard;
