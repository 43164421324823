import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getProject } from "services/projectService";
import JobContentCard from "./JobContentCard";
import { SearchIcon, FilterIcon } from "assets";
import useReactiveSearchParams from "utils/useReactiveSearchParams";

function JobContent() {
  const [meta, setMeta] = useState({
    total: 0,
    perPage: 10,
    page: 1,
  });
  const [projectsData, setProjectsData] = useState([]);
  const [isOrderBy, setIsOrderBy] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  const getProjectData = async () => {
    try {
      setIsLoading(true);
      const response = await getProject(
        page,
        meta.perPage,
        searchParams.orderBy ?? "Terbaru",
        keyword
      );
      if (isOrderBy) {
        setIsOrderBy(false);
      } else {
        setProjectsData((prevData) => [...prevData, ...response.data]);
      }
      setMeta({
        total: response.meta.total,
        perPage: response.meta.limit,
        page: response.meta.page,
      });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProjectData();
  }, [page, isOrderBy, keyword]);

  const handleSort = (e) => {
    if (
      e.target.value === "Terbaru" ||
      "Budget Total Tertinggi" ||
      "Budget Total Terendah" ||
      "Deadline Terlama" ||
      "Deadline Tercepat" ||
      "Media Pitching Paling Sedikit" ||
      "Media Pitching Terbanyak"
    ) {
      setProjectsData([]);
      setPage(1);
      setIsOrderBy(true);
      setSearchParams({ ...searchParams, orderBy: e.target.value });
    } else {
      setIsOrderBy(false);
    }
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
    setProjectsData([]);
  }

  return (
    <>
      <div className="flex justify-between space-x-2">
        <div className="flex w-[443px] space-x-4 px-4 py-[7px] text-sm bg-white border border-border rounded-xl hover:border-primary focus:outline-none">
          <button className="focus:outline-none">
            <SearchIcon />
          </button>
          <input
            type="text"
            placeholder="Cari rilis"
            className="w-full focus:outline-none"
            onChange={(e) => handleChange(e)}
            value={keyword}
          />
        </div>

        <div
          className="group relative flex justify-center items-center focus:outline-none"
          tabIndex="0"
        >
          <div className="flex items-center px-2 md:px-4 py-2 border border-primary rounded-xl text-sm">
            <div className="text-primary md:mr-[10px]">
              <FilterIcon />
            </div>
            <select
              name="Sort"
              id="sort-menu"
              className="outline-none bg-inherit"
              onChange={(e) => handleSort(e)}
              value={searchParams.orderBy}
            >
              <option value="Terbaru">Terbaru</option>
              <option value="Budget Total Tertinggi">
                Budget Total Tertinggi
              </option>
              <option value="Budget Total Terendah">
                Budget Total Terendah
              </option>
              <option value="Deadline Terlama">Deadline Terlama</option>
              <option value="Deadline Tercepat">Deadline Tercepat</option>
              <option value="Media Pitching Paling Sedikit">
                Media Pitching Paling Sedikit
              </option>
              <option value="Media Pitching Terbanyak">
                Media Pitching Terbanyak
              </option>
            </select>
          </div>
        </div>
      </div>
      <InfiniteScroll
        dataLength={projectsData.length}
        next={() => setPage((x) => x + 1)}
        hasMore={page * meta.perPage < meta.total}
        className="flex flex-col space-y-4"
      >
        {projectsData.length > 0 ? (
          projectsData.map((feedItem, i) => (
            <JobContentCard key={i} project={feedItem} />
          ))
        ) : (
          <JobContentCard noProject={true} isLoading={isLoading} isError={isError} />
        )}
      </InfiniteScroll>
    </>
  );
}

export default JobContent;
