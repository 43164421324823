import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { format, parseISO } from "date-fns";
import { UsersIcon } from "assets";
import { Alert } from "components";
import { getDownline, generateCode } from "services/profileService";

function ReferralCard({ profile, isLoading, refresh, setRefresh }) {
  const [downline, setDownline] = useState([]);
  const [limitPage, setLimitPage] = useState(10);
  const [totalRows, setTotalRows] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchDownline = async (page) => {
    setLoading(true);
    try {
      const response = await getDownline(page, limitPage, "");
      setDownline(response.data.list);
      setTotalRows(response.data.totalData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchDownline(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await getDownline(page, newPerPage, "");
      setDownline(response.data.list);
      setLimitPage(newPerPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDownline(1);
  }, []);

  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#E8E8E8",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "Tanggal Registrasi",
      selector: (row) => row.created_at,
      wrap: true,
      cell: (row) => format(parseISO(row.created_at), "d MMMM y HH:mm"),
    },
    {
      name: "Nama Media",
      selector: (row) => row.company_name,
      wrap: true,
      grow: 2,
    },
  ];

  function TableComponent() {
    return (
      <DataTable
        columns={columns}
        data={downline}
        noDataComponent="Tidak ada data riwayat referral"
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[10, 15, 20]}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        striped
      />
    );
  }

  const [isCopied, setIsCopied] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (profile?.referralCode === null) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [profile?.referralCode]);

  const handleCopy = () => {
    navigator.clipboard.writeText(profile.referralCode);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleGenerate = async () => {
    setLoading(true);
    try {
      const response = await generateCode(profile?.id);
      if (response) {
        setLoading(false);
        setRefresh(refresh + 1);
        Alert.fire({
          icon: "success",
          title: "Sukses",
          text: "Berhasil generate kode referral!",
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="mt-4 lg:mt-0 w-full lg:max-w-[787px] h-max p-4 flex flex-col space-y-4 bg-white lg:rounded-2xl shadow-main">
        <div className="flex items-center space-x-4">
          <UsersIcon />
          <h3 className="text-lg font-bold">Manajemen Referral</h3>
        </div>
        <hr />
        <div className="my-8 flex flex-col space-y-4">
          <p className="text-sm">
            Silahkan bagikan kode referral Anda. Jika ada media yang mendaftar
            menggunakan kode referral milik media Anda, maka Anda akan
            mendapatkan komisi sesuai ketentuan dari Tim Penakita.
          </p>

          {isLoading && !profile?.referralCode && (
            <div className="w-auto sm:w-96 animate-pulse overflow-hidden">
              <div className="w-full p-5 bg-gray-200 rounded"></div>
            </div>
          )}
          {!isLoading && !profile?.referralCode && (
            <button
              className="w-auto sm:w-96 bg-primary hover:bg-primary/90 text-white text-sm px-4 py-2 mr-4 rounded"
              onClick={handleGenerate}
            >
              Generate Referral Code
            </button>
          )}

          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="p-2 bg-white border shadow rounded w-auto sm:w-96 mr-4">
              {isLoading && !profile?.referralCode && (
                <div className="w-auto sm:w-80 animate-pulse overflow-hidden">
                  <div className="w-full p-5 bg-gray-200 rounded"></div>
                </div>
              )}
              {!isLoading && (
                <div className="flex justify-between items-center">
                  <input
                    type="text"
                    className="w-full bg-gray-100 rounded p-2 mr-4 border focus:outline-none focus:border-blue"
                    defaultValue={
                      profile?.referralCode
                        ? profile.referralCode
                        : "Belum ada referral code"
                    }
                    disabled
                  />
                  <div className="flex justify-center items-center space-x-2">
                    <button
                      className={`${
                        isDisabled
                          ? "bg-text-secondary cursor-not-allowed"
                          : "bg-blue hover:bg-blue/90"
                      } text-white text-sm px-4 py-2 rounded`}
                      onClick={handleCopy}
                      disabled={isDisabled}
                    >
                      Salin
                    </button>
                  </div>
                </div>
              )}
            </div>
            {isCopied ? (
              <p className="mt-4 sm:mt-0 text-blue text-sm">
                Berhasil menyalin kode referral!
              </p>
            ) : null}
          </div>

          <p className="font-bold">Riwayat referral</p>

          <div className="rounded">{TableComponent()}</div>
        </div>
      </div>
    </>
  );
}

export default ReferralCard;
