import { BarIcon, CalendarIcon, logo, account } from "assets";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import indoLocale from "date-fns/locale/id";
import { currency } from "utils";

function WorkContentCard({ work }) {
  return (
    <div
      className="mt-4 bg-white rounded-2xl border border-border shadow-main"
      key={work?.projectTransactionId}
    >
      <div className="p-4 flex flex-col md:flex-row md:space-x-4">
        <div className="hidden md:block min-w-max">
          <img
            src={work?.image ?? logo}
            alt={work?.name}
            className="rounded-2xl w-[123px] h-[123px] object-cover"
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = logo;
            }}
          />
        </div>
        <div className="flex flex-col space-y-4 w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <img
                src={work?.createdBy?.profilePicture ?? account}
                alt={work?.projectId}
                className="w-6 h-6 object-cover"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = account;
                }}
              />
              <span className="text-sm text-text-secondary">
                {work?.createdBy?.companyName}
              </span>
            </div>
            <span className="px-3 bg-error text-sm text-white rounded-full flex items-center">
              {work?.status}
            </span>
          </div>
          <div className="md:flex md:space-x-4">
            <div className="flex md:flex-col space-y-4 justify-between w-full md:w-9/12">
              <h3 className="text-sm md:text-lg font-bold captilalize">
                {work?.name}
              </h3>
              <p className="hidden md:block text-sm text-text-secondary">
                {work?.description?.length > 230
                  ? work.description.slice(0, 230) + "..."
                  : work?.description}
              </p>
              <div className="block md:hidden min-w-max">
                {work?.image ? (
                  <img
                    src={work?.image}
                    alt={work?.name}
                    className="rounded-2xl w-16 md:w-[123px] h-16 md:h-[123px] object-cover"
                  />
                ) : (
                  <div className="bg-text-secondary rounded-2xl w-16 md:w-[123px] h-16 md:h-[123px]"></div>
                )}
              </div>
              <div className="hidden md:block">
                <Link to={`${work?.projectTransactionId}/detail`}>
                  <button className="px-3 py-[9px] w-[170px] rounded-xl text-primary border border-primary text-sm font-bold">
                    Lihat Detail Pekerjaan
                  </button>
                </Link>
              </div>
            </div>
            <div className="hidden md:block border border-border"></div>
            <div className="mt-4 md:mt-0 flex flex-wrap md:flex-col md:space-y-4 md:w-3/12">
              <div className="mr-8 md:mr-0 mb-4 md:mb-0 flex space-x-4 items-center text-text-secondary">
                <CalendarIcon />
                <div className="flex flex-col space-y-2 w-max">
                  <span className="text-sm">Deadline</span>
                  <span className="text-sm text-text-primary">
                    <span className="font-bold">
                      {format(parseISO(work?.deadLine), "d MMMM y", {
                        locale: indoLocale,
                      })}
                    </span>
                  </span>
                </div>
              </div>
              <div className="flex space-x-4 items-center text-text-secondary">
                <BarIcon />
                <div className="flex flex-col space-y-2 w-max">
                  <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0">
                    <span className="text-sm w-24">Rate Korporasi</span>
                    <span className="text-sm text-text-primary font-bold">
                      {currency(work?.ratePublisherBrandContent)}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0">
                    <span className="text-sm w-24">Rate UMKM</span>
                    <span className="text-sm text-text-primary font-bold">
                      {currency(work?.rateSme)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="block md:hidden">
              <Link to={`${work?.projectTransactionId}/detail`}>
                <button className="mt-4 px-3 py-[9px] w-full md:w-[170px] rounded-xl text-primary border border-primary text-sm font-bold">
                  Lihat Detail Pekerjaan
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkContentCard;
