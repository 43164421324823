import { CheckIcon } from "assets";

export default function RegisterProgress({ step }) {
  return (
    <div className="md:px-16 flex items-center justify-between">
      <div
        className={`w-8 h-8 rounded-full inline-flex items-center justify-center font-bold text-sm ${
          Number(step) === 1 ? "bg-primary text-white" : ""
        } ${Number(step) > 1 ? "bg-primary text-white" : ""}`}
      >
        {Number(step) > 1 ? <CheckIcon /> : "1"}
      </div>
      <div className="flex-1 rounded overflow-hidden bg-soft">
        <div className="py-px w-full bg-primary"></div>
      </div>
      <div
        className={`w-8 h-8 rounded-full inline-flex items-center justify-center font-bold text-sm ${
          Number(step) >= 2 ? "bg-primary text-white" : ""
        } ${
          Number(step) < 2
            ? "bg-background-orange text-primary border border-primary"
            : ""
        }`}
      >
        {Number(step) > 2 ? <CheckIcon /> : "2"}
      </div>
      <div className="flex-1 rounded overflow-hidden bg-soft">
        <div className="py-px w-full bg-primary"></div>
      </div>
      <div
        className={`w-8 h-8 rounded-full inline-flex items-center justify-center font-bold text-sm ${
          Number(step) === 3 ? "bg-primary text-white" : ""
        } ${
          Number(step) < 3
            ? "bg-background-orange text-primary border border-primary"
            : ""
        }`}
      >
        3
      </div>
    </div>
  );
}
