function ErrorCard({ text, className }) {
  return (
    <div className={`${className} bg-white rounded-2xl border border-border shadow-main`}>
      <div className="p-8 text-center">
        <p className="font-bold">{text}</p>
      </div>
    </div>
  );
}

export default ErrorCard;
