import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { updateAddress, getProvince, getCity } from "services/profileService";
import { Alert } from "components";

function AddressCard({ profile, refresh, setRefresh }) {
  const validationSchema = Yup.object().shape({
    provinceId: Yup.string().required("Provinsi tidak boleh kosong"),
    cityId: Yup.string().required("Kota tidak boleh kosong"),
    address: Yup.string().required("Alamat tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      provinceId: "",
      cityId: "",
      address: "",
    },
  });

  const [provinceId, setProvinceId] = useState(profile?.provinceId);
  const [cityId, setCityId] = useState(profile?.cityId);

  useEffect(() => {
    setProvinceId(profile?.provinceId);
    setCityId(profile?.cityId);
    let defaults = {
      provinceId: profile?.provinceId,
      cityId: profile?.cityId,
      address: profile?.address,
    };
    reset(defaults);
  }, [profile, reset]);

  const [provinceList, setProvinceList] = useState([]);
  useEffect(() => {
    const fetchProvince = async () => {
      let result = {};
      const response = await getProvince();
      setProvinceList(response);
      return result;
    };
    fetchProvince();
  }, []);

  const changeProvince = (e) => {
    setProvinceId(e.target.value);
    reset({ cityId: "" });
  };

  const [cityList, setCityList] = useState([]);
  useEffect(() => {
    const fetchCity = async () => {
      let result = {};
      const response = await getCity(provinceId);
      setCityList(response);
      return result;
    };
    if (provinceId) {
      fetchCity();
    }
  }, [provinceId]);

  const onSubmit = (e) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah alamat!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Konfirmasi",
    }).then((result) => {
      if (result.isConfirmed) {
        updateAddress(e.provinceId, e.cityId, e.address)
          .then((res) => {
            if (res) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Alamat berhasil diubah.",
              });
            }
          })
          .catch((res) => {
            if (res.response.status !== 401) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${res.response.data.message}`,
              });
            }
          });
      }
    });
  };

  return (
    <>
      <div className="mt-4 lg:mt-0 w-full lg:max-w-[787px] h-max p-4 flex flex-col space-y-4 bg-white lg:rounded-2xl shadow-main">
        <div className="flex items-center space-x-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="text-lg font-bold">Ubah Alamat</h3>
        </div>
        <hr />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-8 grid md:grid-cols-2 gap-4">
            <div className="flex flex-col">
              <label className="text-sm font-bold">Provinsi</label>
              <select
                name="provinceId"
                {...register("provinceId")}
                className="mt-2 px-4 py-2 text-sm form-control"
                value={provinceId}
                onChange={(e) => changeProvince(e)}
              >
                <option value="">Pilih Provinsi</option>
                {provinceList &&
                  provinceList.map((province) => (
                    <option value={province.id} key={province.id}>
                      {province.name}
                    </option>
                  ))}
              </select>
              <div className="mt-1 invalid-feedback">
                {errors.provinceId?.message}
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-bold">Kota</label>
              <select
                name="cityId"
                {...register("cityId")}
                className="mt-2 px-4 py-2 text-sm form-control"
                value={cityId}
                onChange={(e) => setCityId(e.target.value)}
              >
                <option value="">Pilih Kota</option>
                {cityList &&
                  cityList.map((city) => (
                    <option value={city.id} key={city.id}>
                      {city.name}
                    </option>
                  ))}
              </select>
              <div className="mt-1 invalid-feedback">
                {errors.cityId?.message}
              </div>
            </div>
            <div className="flex flex-col md:col-span-2">
              <label className="text-sm font-bold">Alamat Lengkap</label>
              <textarea
                name="address"
                {...register("address")}
                placeholder="Masukkan alamat lengkap"
                className="h-[72px] mt-2 px-4 py-2 text-sm resize-none form-control"
              />
              <div className="mt-1 invalid-feedback">
                {errors.address?.message}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 w-full lg:w-auto lg:px-16 py-[10px] bg-primary text-white text-sm font-bold rounded-xl"
            >
              Simpan
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddressCard;
