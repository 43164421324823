import { useContext } from "react";
import { Layout, Sidebar, Nav, Header, Main } from "components";
import Title from "components/Title";
import JobSection from "./components/JobSection";
import AlertCard from "./components/AlertCard";
import JobContent from "./components/JobContent";
import { ProfileContext } from "contexts/ProfileContext";

function Job() {
  const { profilesData } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Ambil Rilis" />
          <JobSection>
            <AlertCard rate={profilesData?.ratePublisherBrandContent} />
            <JobContent />
          </JobSection>
        </Main>
      </Layout>
    </>
  );
}

export default Job;
