import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { verify } from "services/authService";

function Form() {
  const { token } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const checkToken = async () => {
    let result = {};
    try {
      const response = await verify(token);
      if (response.code === 200) {
        setIsModalOpen(true);
        setIsError(false);
      } else {
        setIsModalOpen(true);
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
      throw error;
    }
    return result;
  };

  useEffect(() => {
    checkToken();
  }, []);

  const relogin = () => {
    window.location.replace("/");
  };

  return (
    <>
      <div className="flex flex-col mt-4 md:mt-0 mb-8 md:mb-0 py-8 md:py-16 px-4 md:w-[410px] md:h-[548px] rounded-2xl md:rounded-none md:rounded-r-2xl bg-white">
        <div className="w-full">
          <h5 className="mb-8 text-2xl font-bold text-center">
            Verifikasi Akun
          </h5>
          <p className="mt-8 text-sm text-center">
            Terima kasih telah melakukan verifikasi akun. Silahkan melanjutkan aktivitas Anda.
          </p>
        </div>
      </div>

      {/* Modal */}
      <div
        className={`${
          isModalOpen ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <div className="p-8 w-full flex flex-col">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.3332 36.9332V39.9999C73.3291 47.1879 71.0015 54.1821 66.6976 59.9393C62.3937 65.6965 56.3441 69.9081 49.451 71.9462C42.5579 73.9843 35.1907 73.7395 28.4481 71.2485C21.7054 68.7574 15.9487 64.1535 12.0364 58.1234C8.12406 52.0933 6.26581 44.9601 6.73876 37.7876C7.21171 30.6151 9.99053 23.7876 14.6608 18.3235C19.331 12.8593 25.6425 9.05115 32.6538 7.46703C39.6651 5.88291 47.0007 6.60766 53.5665 9.53321"
                stroke="url(#paint0_linear_70_2489)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M73.3333 13.333L40 46.6997L30 36.6997"
                stroke="url(#paint1_linear_70_2489)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_70_2489"
                  x1="39.9998"
                  y1="6.64746"
                  x2="39.9998"
                  y2="73.3142"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_70_2489"
                  x1="51.6667"
                  y1="13.333"
                  x2="51.6667"
                  y2="46.6997"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
              </defs>
            </svg>

            <h1 className={`${!isError ? "text-text-primary" : "text-error"} mt-8 text-2xl font-bold`}>
              {!isError && "Berhasil verifikasi akun Anda"}
              {isError && "Terjadi kesalahan"}
            </h1>
            <p className="mt-4 text-sm text-text-secondary">
              {!isError && "Terima kasih telah melakukan verifikasi akun. Silahkan melanjutkan aktivitas Anda."}
              {isError && "Mohon maaf ada kesalahan silahkan coba kembali."}
            </p>
            <button
              className="mt-8 w-full px-4 py-2 font-bold bg-gradient text-white rounded-xl ease-in-out duration-200 hover:shadow-lg"
              onClick={() => relogin()}
            >
              Lanjut
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default Form;
