import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "assets/styles/index.css";
import App from "./App";
import ProfileContextProvider from "contexts/ProfileContext";
import ScrollToTop from "utils/useScrollToTop";

ReactDOM.render(
  <BrowserRouter>
    <ProfileContextProvider>
      <ScrollToTop />
      <App />
    </ProfileContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
