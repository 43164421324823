import { useContext } from "react"
import { ProfileContext } from "contexts/ProfileContext";
import { Layout, Sidebar, Nav, Header, Main, ProfileMenu, ProfileNav } from "components";
import Title from "components/Title";
import ReferralSection from "./components/ReferralSection";
import ReferralCard from "./components/ReferralCard";

function Referral() {
  const { profilesData, isLoading, refresh, setRefresh } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Profile" />
          <ReferralSection>
            <ProfileMenu>
              <ProfileNav profile={profilesData} />
            </ProfileMenu>
            <ReferralCard profile={profilesData} isLoading={isLoading} refresh={refresh} setRefresh={setRefresh} />
          </ReferralSection>
        </Main>
      </Layout>
    </>
  );
}

export default Referral;
