import { useContext } from "react";
import { Layout, Sidebar, Nav, Header, Main, ProfileMenu, ProfileNav } from "components";
import Title from "components/Title";
import ProfileSection from "./components/ProfileSection";
import ProfileCard from "./components/ProfileCard";
import { ProfileContext } from "contexts/ProfileContext";

function Profile() {
  const { profilesData, refresh, setRefresh } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Profile" />
          <ProfileSection>
            <ProfileMenu>
              <ProfileNav profile={profilesData} />
            </ProfileMenu>
            <ProfileCard profile={profilesData} refresh={refresh} setRefresh={setRefresh} />
          </ProfileSection>
        </Main>
      </Layout>
    </>
  );
}

export default Profile;
