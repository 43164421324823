import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { Layout, Sidebar, Nav, Header, Main } from "components";
import Title from "components/Title";
import WorkSection from "./components/WorkSection";
import WorkContent from "./components/WorkContent";

function Work() {
  const { profilesData } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Pekerjaan" />
          <WorkSection>
            <WorkContent />
          </WorkSection>
        </Main>
      </Layout>
    </>
  );
}

export default Work;
