import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { login } from "services/authService";
import { EyeIcon, EyeOffIcon } from "assets";

function Form() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email tidak boleh kosong")
      .email("Format email salah"),
    password: Yup.string().required("Password tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmit = (e) => {
    setLoading(true);
    login(e.email, e.password)
      .then((res) => {
        setLoading(false);
        setMessage(res.data.message);
        navigate("/dashboard", { replace: true });
        window.location.reload();
      })
      .catch((res) => {
        setLoading(false);
        setMessage(res.response.data.message);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center mt-4 md:mt-0 mb-8 md:mb-0 px-4 py-8 md:py-16 md:w-[410px] rounded-2xl md:rounded-none md:rounded-r-2xl bg-white">
      <div className="w-full">
        <h5 className="mb-8 text-2xl font-bold text-center">Masuk</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-4 text-sm">
            <div className="flex flex-col space-y-2">
              <label className="font-bold">Email</label>
              <input
                type="text"
                name="email"
                {...register("email")}
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                placeholder="Masukkan email"
                autoComplete="email"
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
            <div className="flex flex-col space-y-2 relative">
              <label className="font-bold">Password</label>
              <input
                type={`${isRevealPassword ? "text" : "password"}`}
                name="password"
                {...register("password")}
                className={`form-control pr-10 ${
                  errors.password ? "is-invalid" : ""
                }`}
                placeholder="Masukkan password"
                autoComplete="current-password"
              />

              <div
                className="absolute top-[35%] right-3 text-gray-400"
                onClick={() => setIsRevealPassword((prevState) => !prevState)}
              >
                {isRevealPassword ? <EyeOffIcon /> : <EyeIcon />}
              </div>
              {errors.password ? (
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
              ) : (
                <div className="pt-4"></div>
              )}
            </div>
          </div>
          {loading ? (
            <div className="mt-4 text-center text-sm">
              <span>Loading...</span>
            </div>
          ) : (
            message && (
              <div className="mt-4 text-center text-sm">
                <span className="text-dark-red font-bold capitalize">
                  {message}
                </span>
              </div>
            )
          )}
          <button className="mt-6 bg-gradient w-full py-3 rounded-xl text-sm text-white font-bold">
            Masuk
          </button>
        </form>
        <div className="mt-4 mb-8 text-center">
          <a href="/forgot-password" className="text-primary text-sm font-bold">
            Lupa Password?
          </a>
        </div>
        <hr />
        <div className="mt-8 flex flex-col text-center">
          <span className="text-sm">Belum punya akun?</span>
          <div className="mt-4">
            <a href="/register" className="text-primary text-sm font-bold">
              Daftar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Form;
