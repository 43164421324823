import { Link } from "react-router-dom";
import { UserIcon, NotifIcon, HelpIcon } from "assets";
import { format, parseISO } from "date-fns";

export default function HeaderContentRight({
  isVerified,
  isLoading,
  isError,
  notifData,
  setIsModalOpen,
}) {
  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  return (
    <div className="flex justify-end xl:justify-between space-x-2 xl:space-x-0 xl:w-3/12 mr-4 xl:mr-0">
      <div className="flex flex-col">
        <div className="flex items-center">
          <span className="mr-1 text-xs text-text-secondary">
            Status Verifikasi
          </span>
          <button className="text-primary" onClick={() => setIsModalOpen(true)}>
            <HelpIcon />
          </button>
        </div>
        <div>
          {!isLoading && isVerified !== 0 && (
            <span className="px-1 py-px bg-success text-white text-[10px] rounded-full">
              Terverifikasi
            </span>
          )}
          {!isLoading && isVerified === 0 && (
            <span className="px-1 py-px bg-error text-white text-[10px] rounded-full">
              Belum Terverifikasi
            </span>
          )}
          {isLoading && (
            <div className="mt-2 w-20 animate-pulse overflow-hidden">
              <div className="w-full p-2 bg-gray-200 rounded"></div>
            </div>
          )}
        </div>
      </div>
      <div className="flex space-x-2 xl:space-x-8">
        <div
          className="group relative flex justify-center items-center focus:outline-none"
          tabIndex="0"
        >
          <button className="text-primary">
            <UserIcon />
          </button>
          <nav
            tabIndex="0"
            className="border bg-white shadow-main invisible rounded-2xl w-auto absolute -left-10 top-full transition-all opacity-0 group-focus-within:visible group-focus-within:opacity-100 group-focus-within:translate-y-1 focus:outline-none"
          >
            <ul>
              <li>
                <Link to="/profile">
                  <button className="block w-24 px-4 py-2 rounded-t-2xl hover:bg-background-orange hover:text-primary focus:outline-none">
                    Profile
                  </button>
                </Link>
              </li>
              <li>
                <button
                  className="block w-24 px-4 py-2 rounded-b-2xl hover:bg-background-orange hover:text-primary focus:outline-none"
                  onClick={handleLogout}
                >
                  Log Out
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div
          className="group relative flex justify-center items-center focus:outline-none"
          tabIndex="0"
        >
          <button className="text-primary">
            <NotifIcon />
            <span className="animate-ping absolute top-2 right-1 block h-1 w-1 rounded-full ring-2 ring-green-400 bg-green-600"></span>
          </button>
          <nav
            tabIndex="0"
            className="border bg-white shadow-main invisible rounded-2xl w-72 absolute -right-6 top-full z-10 transition-all opacity-0 group-focus-within:visible group-focus-within:opacity-100 group-focus-within:translate-y-1 focus:outline-none"
          >
            <ul>
              {isError && (
                <li className="p-4 text-center text-sm text-pk-error">
                  Saat ini tidak ada notifikasi.
                </li>
              )}
              {notifData &&
                notifData.map((data, i) => (
                  <li
                    className="flex flex-col space-y-1 px-4 py-3 hover:bg-pk-soft"
                    key={i}
                  >
                    <p className="text-sm font-bold">{data.message}</p>
                    <p className="text-xs">
                      {data?.createdAt &&
                        format(parseISO(data.createdAt), "d MMM y HH:mm")}
                    </p>
                  </li>
                ))}
              {notifData?.length > 0 && (
                <Link to="/notifications">
                  <button className="w-full bg-text-secondary text-white text-center text-sm font-bold py-2 rounded-b-2xl">
                    Lihat semua notifikasi
                  </button>
                </Link>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
