function FeedLoader() {
  return (
    <div className="mt-4 flex flex-col p-4 h-auto bg-white rounded-2xl shadow-main">
      <div className="flex-shrink-0 w-full animate-pulse overflow-hidden aspect-auto">
        <div className="flex space-x-4">
          <div className="min-w-[123px] h-[123px] rounded-2xl bg-gray-200"></div>
          <div className="w-full flex flex-col">
            <div className="flex flex-wrap justify-between">
              <div className="w-1/12 p-2 bg-gray-200"></div>
              <div className="w-1/12 p-2 bg-gray-200"></div>
            </div>
            <div className="mt-4 w-1/2 p-2 bg-gray-200"></div>
            <div className="mt-4 w-full p-2 bg-gray-200"></div>
            <div className="mt-2 w-full p-2 bg-gray-200"></div>
            <div className="mt-2 w-full p-2 bg-gray-200"></div>
            <div className="mt-2 w-1/4 p-2 bg-gray-200"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeedLoader;
