import { useContext } from "react";
import { Layout, Sidebar, Nav, Header, Main, ProfileMenu, ProfileNav } from "components";
import Title from "components/Title";
import RateSection from "./components/RateSection";
import RateCard from "./components/RateCard";
import { ProfileContext } from "contexts/ProfileContext";

function Rate() {
  const { profilesData, refresh, setRefresh } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Profile" />
          <RateSection>
            <ProfileMenu>
              <ProfileNav profile={profilesData} />
            </ProfileMenu>
            <RateCard profile={profilesData} refresh={refresh} setRefresh={setRefresh} />
          </RateSection>
        </Main>
      </Layout>
    </>
  );
}

export default Rate;
