import { format, parseISO } from "date-fns";

function MainContentCard({
  image,
  username,
  photo,
  created,
  title,
  description,
  objective,
}) {
  return (
    <>
      <div className="pb-4 w-full h-full bg-white rounded-2xl shadow-main">
        <article className="p-4 flex flex-col space-y-4">
          <div>
            {image && (
              <img
                src={image}
                alt={title}
                className="rounded-2xl w-auto md:w-[640px] md:h-[360px] object-cover"
              />
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {photo ? (
                <img
                  src={photo}
                  className="w-6 h-6 rounded-full"
                  alt={username}
                />
              ) : (
                <div className="border bg-text-secondary w-6 h-6 rounded-full"></div>
              )}
              <span className="text-sm">{username}</span>
            </div>
            <div className="text-sm">
              <span className="hidden md:inline-block font-bold">
                Dibuat pada
              </span>
              <span className="text-text-secondary">
                {created && format(parseISO(created), " d MMMM y")}
              </span>
            </div>
          </div>

          <h3 className="text-base md:text-lg font-bold capitalize">{title}</h3>
          <p className="text-sm text-text-secondary">{description}</p>
          <h4 className="pt-4 font-bold">Objektif</h4>
          <p className="text-sm text-text-secondary">{objective}</p>
        </article>
      </div>
    </>
  );
}

export default MainContentCard;
