import { useState } from "react";
import { AuthLayout, AuthMain, AuthLeft } from "components";
import FormRegister1 from "./components/FormRegister1";
import FormRegister2 from "./components/FormRegister2";
import FormRegister3 from "./components/FormRegister3";
import RegisterProgress from "./components/RegisterProgress";

function Register() {
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    companyEmail: "",
    password: "",
    passwordConfirmation: "",
    companyName: "",
    companyWebsite: "",
    picEmail: "",
    categoryMediaId: "",
    typeMediaId: "",
    segmentationMediaId: "",
    referralCode: "",
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return (
    <>
      <AuthLayout>
        <AuthMain>
          <AuthLeft />
          <div className="flex flex-col items-center justify-center mt-4 md:mt-0 mb-8 md:mb-0 px-4 py-8 md:w-[410px] rounded-2xl md:rounded-none md:rounded-r-2xl bg-white">
            <div className="w-full">
              <h5 className="mb-10 text-2xl font-bold text-center">Daftar</h5>
              <RegisterProgress step={step} />
              {step === 1 && (
                <FormRegister1
                  nextStep={nextStep}
                  handleFormData={handleInputData}
                  values={formData}
                />
              )}
              {step === 2 && (
                <FormRegister2
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={handleInputData}
                  values={formData}
                />
              )}
              {step === 3 && (
                <FormRegister3
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={handleInputData}
                  values={formData}
                />
              )}
              <hr />
              <div className="mt-8 flex flex-col text-center">
                <span className="text-sm">Sudah punya akun?</span>
                <div className="mt-4">
                  <a href="/login" className="text-primary text-sm font-bold">
                    Masuk
                  </a>
                </div>
              </div>
            </div>
          </div>
        </AuthMain>
      </AuthLayout>
    </>
  );
}

export default Register;
