import { logo } from "assets";

function LeftSide() {
  return (
    <div className="flex flex-col items-center justify-center px-4 pb-4 md:pb-0 rounded-2xl md:rounded-none md:rounded-l-2xl bg-background md:max-w-[262px]">
      <img src={logo} alt="logo" />
      <p className="mt-4 md:mt-8 mb-4 text-lg font-bold text-center">
        Selamat datang partner media PenaKita!
      </p>
      <p className="text-sm text-text-secondary text-center">
        Di PenaKita, anda bisa mencari rilis yang bisa dipublikasikan di media
        Anda, atau mendapatkan permintaan publikasi dari berbagai macam klien!
      </p>
    </div>
  );
}
export default LeftSide;
