import { AuthLayout, AuthMain, AuthLeft } from "components";
import Form from "./components/Form";

function Verify() {
  return (
    <>
      <AuthLayout>
        <AuthMain>
          <AuthLeft />
          <Form />
        </AuthMain>
      </AuthLayout>
    </>
  );
}

export default Verify;
