import { currency } from "utils";

function AlertCard({ rate }) {
  return (
    <div className="px-4 py-2 bg-background-orange border border-primary rounded-lg">
      <span className="text-sm">
        Silahkan pilih rilis yang ingin diambil dan dimuat di media Anda. Rate
        publikasi akan mengikuti tarif yang sudah Anda tentukan.{" "}
      </span>
      <span className="text-sm font-bold">{currency(rate)}</span>
    </div>
  );
}

export default AlertCard;
