import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getNotifications } from "services/profileService";

function NotificationCard() {
  const [notifications, setNotifications] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await getNotifications(1, 3, "Semua");
      if (response.data.length > 0) {
        setNotifications(response.data);
        setIsLoading(false);
      } else {
        setIsError(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="p-4 flex flex-col space-y-4 bg-white rounded-2xl shadow-main">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-bold">Notifikasi</h3>
        <Link to="/notifications" className="text-primary text-sm font-bold">
          Lihat Semua
        </Link>
      </div>
      <hr />
      {isLoading && (
        <div className="pt-8 w-full animate-pulse overflow-hidden">
          <div className="flex flex-col items-center">
            <div className="w-1/12 p-2 bg-gray-200 rounded"></div>
            <div className="mt-6 w-1/2 p-2 bg-gray-200 rounded"></div>
          </div>
        </div>
      )}
      {!isError && !isLoading && notifications && (
        <div className="flex flex-col space-y-2">
          {notifications.map((data, index) => (
            <div
              className="px-4 py-3 rounded-lg border border-text-secondary hover:bg-background-orange hover:border-primary"
              key={index}
            >
              <p className="text-sm">{data.message}</p>
            </div>
          ))}
        </div>
      )}
      {isError && (
        <div className="py-8 flex flex-col items-center space-y-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
              stroke="#7A8796"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21"
              stroke="#7A8796"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <p className="text-sm text-text-secondary">Belum ada notifikasi</p>
        </div>
      )}
    </div>
  );
}

export default NotificationCard;
