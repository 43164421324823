import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getWork, getSummary } from "services/projectService";
import WorkContentCard from "./WorkContentCard";
import useReactiveSearchParams from "utils/useReactiveSearchParams";
import { FeedLoader, ErrorCard } from "components";

function WorkContent() {
  const [meta, setMeta] = useState({
    total: 0,
    perPage: 10,
    page: 1,
  });
  const [projectsData, setProjectsData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [isType, setType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchParams, setSearchParams] = useReactiveSearchParams();
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState([]);
  const [statusValue, setStatusValue] = useState("Semua");
  const [typeValue, setTypeValue] = useState("Semua");
  const [isActiveStatus, setIsActiveStatus] = useState("Semua");
  const [isActiveType, setIsActiveType] = useState("Semua");

  const getProjectData = async () => {
    let result = {};
    try {
      const response = await getWork(
        page,
        meta.perPage,
        statusValue,
        typeValue
      );
      if (isFilter && isType) {
        setProjectsData(response.data);
        setIsFilter(false);
        setType(false);
        setIsLoading(false);
      } else {
        setProjectsData((prevData) => [...prevData, ...response.data]);
      }
      setMeta({
        total: response.meta.total,
        perPage: response.meta.limit,
        page: response.meta.page,
      });
      setIsLoading(false);
      if (response.data.length <= 0) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      throw error;
    }
    return result;
  };

  const fetchStatusBar = async () => {
    setIsLoading(true);
    let result = {};
    try {
      const response = await getSummary();
      setFilterData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
    return result;
  };

  useEffect(() => {
    getProjectData();
  }, [page, statusValue, typeValue]);

  useEffect(() => {
    fetchStatusBar();
  }, []);

  const filterStatus = (status) => {
    setIsActiveStatus(status);
    if (status !== statusValue) {
      setProjectsData([]);
      setPage(1);
      setIsFilter(true);
      setSearchParams({ ...searchParams, status: status });
      setStatusValue(status);
    } else {
      setIsFilter(false);
    }
  };
  const typeStatus = (type) => {
    setIsActiveType(type);
    if (type !== typeValue) {
      setProjectsData([]);
      setPage(1);
      setType(true);
      setSearchParams({ ...searchParams, type: type });
      setTypeValue(type);
    } else {
      setType(false);
    }
  };

  function categoryCard() {
    return (
      <div className="flex flex-wrap">
        {filterData &&
          filterData.slice(0, 5).map((data, index) => (
            <button
              className={`${
                isActiveStatus === data.status
                  ? "border-primary bg-background-orange text-primary"
                  : "border-text-secondary bg-white text-text-secondary"
              } px-4 py-[5px] border text-sm rounded-lg flex items-center space-x-2 mr-2 mb-2 sm:mb-0`}
              key={index}
              id={index}
              onClick={() => filterStatus(data.status)}
            >
              <span>{data.status}</span>
            </button>
          ))}
      </div>
    );
  }

  function statusCard() {
    return (
      <div className="mt-2 sm:mt-4 flex flex-wrap">
        {filterData &&
          filterData.slice(5).map((data, index) => (
            <button
              className={`${
                isActiveType === data.status
                  ? "border-primary bg-background-orange text-primary"
                  : "border-text-secondary bg-white text-text-secondary"
              } px-4 py-[5px] border text-sm rounded-lg flex items-center space-x-2 mr-2 mb-2 sm:mb-0`}
              key={index}
              id={index}
              onClick={() => typeStatus(data.status)}
            >
              <span>{data.status}</span>
            </button>
          ))}
      </div>
    );
  }

  return (
    <>
      {isLoading && (
        <div className="flex-shrink-0 w-full animate-pulse overflow-hidden aspect-auto">
          <div className="flex flex-col">
            <div className="flex space-x-2">
              <div className="w-1/12 p-4 bg-gray-200 rounded"></div>
              <div className="w-1/2 p-4 bg-gray-200 rounded"></div>
            </div>
            <div className="mt-4 flex space-x-2">
              <div className="w-1/12 p-4 bg-gray-200 rounded"></div>
              <div className="w-1/6 p-4 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          {categoryCard()}
          {statusCard()}
        </>
      )}
      <InfiniteScroll
        dataLength={projectsData.length}
        next={() => setPage((x) => x + 1)}
        hasMore={page * meta.perPage < meta.total}
        className="flex flex-col space-y-4"
      >
        {isLoading && [0, 0, 0].map((x, i) => <FeedLoader key={i} />)}
        {!isError &&
          !isLoading &&
          projectsData.length > 0 &&
          projectsData.map((feedItem, i) => (
            <WorkContentCard key={i} work={feedItem} />
          ))}
        {isError && (
          <ErrorCard
            text="Data Tidak Ditemukan!"
            className="mt-4 h-56 flex items-center justify-center text-black"
          />
        )}
      </InfiniteScroll>
    </>
  );
}

export default WorkContent;
