import { useState, useEffect } from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { format, parseISO } from "date-fns";
import indoLocale from "date-fns/locale/id";

const Expired = () => {
  return (
    <h2 className="text-error text-xl font-bold">Pekerjaan telah berakhir</h2>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="flex space-x-4">
      <DateTimeDisplay value={days} type={"Hari"} isDanger={days <= 3} />
      <span className="text-2xl font-bold">:</span>
      <DateTimeDisplay value={hours} type={"Jam"} isDanger={false} />
      <span className="text-2xl font-bold">:</span>
      <DateTimeDisplay value={minutes} type={"Menit"} isDanger={false} />
      <span className="text-2xl font-bold">:</span>
      <DateTimeDisplay value={seconds} type={"Detik"} isDanger={false} />
    </div>
  );
};

const DeadlineCard = ({
  deadline,
  isExpired,
  days,
  hours,
  minutes,
  seconds,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="mb-4 p-4 flex flex-col space-y-4 bg-white rounded-2xl shadow-main">
      <h3 className="text-lg font-bold">Deadline</h3>
      <hr />
      <span className="text-sm font-bold">
        {deadline &&
          format(parseISO(deadline), "d MMMM y", {
            locale: indoLocale,
          })}
      </span>
      {!isLoading &&
        (isExpired ? (
          <Expired />
        ) : (
          <ShowCounter
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
        ))}
      {isLoading && (
        <div className="w-full flex flex-col justify-center animate-pulse overflow-hidden">
          <div className="w-full p-3 bg-gray-200"></div>
          <div className="mt-2 w-full p-3 bg-gray-200"></div>
        </div>
      )}
    </div>
  );
};

export default DeadlineCard;
