function MainContentCard({
  image,
  username,
  photo,
  status,
  title,
  description,
}) {
  return (
    <>
      <div className="mb-4 pb-4 h-auto bg-white rounded-2xl shadow-main">
        <article className="p-4 flex flex-col space-y-4">
          {image && (
            <div className="min-w-max">
              <img
                src={image}
                alt={title}
                className="rounded-2xl w-full xl:w-[640px] max-h-48 xl:max-h-[360px] object-cover"
              />
            </div>
          )}

          <div className="flex justify-between">
            <div className="flex items-center space-x-2">
              {photo ? (
                <img
                  src={photo}
                  className="w-6 h-6 rounded-full"
                  alt={username}
                />
              ) : (
                <div className="border bg-text-secondary w-6 h-6 rounded-full"></div>
              )}
              <span className="text-sm">{username}</span>
            </div>
            <span className="px-3 bg-error text-sm text-white rounded-full flex items-center">
              {status}
            </span>
          </div>

          <h3 className="text-lg font-bold capitalize">{title}</h3>
          <p className="text-sm text-text-secondary whitespace-pre-line">{description}</p>
          {/* <h4 className="pt-4 font-bold">Objektif</h4>
          <p className="text-sm text-text-secondary">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Omnis
            officia excepturi dolor possimus, repellendus est obcaecati
            delectus? Accusantium, assumenda fugit cumque, repellendus eligendi
            neque laborum possimus magnam laboriosam dolore mollitia.
          </p> */}
        </article>
      </div>
    </>
  );
}

export default MainContentCard;
