import { currency } from "utils";

function OtherCard({ projects, projectId }) {
  return (
    <div className="p-4 flex flex-col space-y-4 h-max bg-white rounded-2xl shadow-main">
      <h3 className="text-lg font-bold">Rilis Lainnya</h3>
      <hr />
      <div className="flex flex-col space-y-4">
        {projects
          .filter((x) => x.projectId !== projectId)
          .slice(0, 3)
          .map((project, i) => (
            <div className="flex space-x-4" key={i}>
              {project.image ? (
                <div className="min-w-[91px] h-[91px] object-cover">
                  <img
                    src={project.image}
                    alt={project.name}
                    className="w-[91px] h-[91px] rounded-2xl object-cover"
                  />
                </div>
              ) : (
                <div className="min-w-[91px] h-[91px] rounded-2xl bg-success"></div>
              )}
              <div className="w-4/5 flex flex-col space-y-2">
                <p className="text-sm font-bold leading-4">{project.name}</p>
                <p className="pt-2 text-sm text-text-secondary">
                  Rate Publikasi Brand Content
                </p>
                <div className="flex justify-between">
                  <span className="text-sm font-bold">
                    {currency(project?.totalBudget)}
                  </span>
                  <a
                    className="text-sm text-primary font-bold"
                    href={`../${project.projectId}/detail`}
                  >
                    Lihat detail
                  </a>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default OtherCard;
