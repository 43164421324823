import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { registration } from "services/authService";
import { getMediaCategories } from "services/profileService";

function FormRegister3({ handleFormData, prevStep, values }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const validationSchema = Yup.object().shape({
    typeMediaId: Yup.string().required("Jenis media tidak boleh kosong"),
    segmentationMediaId: Yup.string().required(
      "Cakupan media tidak boleh kosong"
    ),
    categoryMediaId: Yup.string().required("Kategori media tidak boleh kosong"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const [mediaCategoriesList, setMediaCategoriesList] = useState([]);
  const fetchMediaCategories = async () => {
    let result = {};
    const response = await getMediaCategories();
    setMediaCategoriesList(response);
    return result;
  };

  useEffect(() => {
    fetchMediaCategories();
  }, []);

  const onSubmit = () => {
    setLoading(true);
    registration(values)
      .then((res) => {
        if (res.success === true) {
          setLoading(false);
          navigate("/dashboard", { replace: true });
          window.location.reload();
        }
      })
      .catch((res) => {
        if (res.response.data.success === false) {
          setLoading(false);
          setMessage(res.response.data.message);
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-8 flex flex-col space-y-4 text-sm">
          <div className="flex flex-col space-y-2">
            <label className="font-bold">Jenis Media</label>
            <select
              name="typeMediaId"
              defaultValue={values.typeMediaId}
              {...register("typeMediaId")}
              className={`px-4 py-[13px] form-control ${
                errors.typeMediaId ? "is-invalid" : ""
              }`}
              onChange={handleFormData("typeMediaId")}
            >
              <option value="">Pilih jenis media</option>
              <option value="1">Media Online</option>
              <option value="2">Media Cetak</option>
            </select>
            <div className="mt-1 invalid-feedback">
              {errors.typeMediaId?.message}
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="font-bold">Cakupan Media</label>
            <select
              name="segmentationMediaId"
              defaultValue={values.segmentationMediaId}
              {...register("segmentationMediaId")}
              className={`px-4 py-[13px] form-control ${
                errors.segmentationMediaId ? "is-invalid" : ""
              }`}
              onChange={handleFormData("segmentationMediaId")}
            >
              <option value="">Pilih cakupan media</option>
              <option value="1">Nasional</option>
              <option value="2">Regional</option>
            </select>
            <div className="mt-1 invalid-feedback">
              {errors.segmentationMediaId?.message}
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="font-bold">Kategori Media</label>
            <select
              name="categoryMediaId"
              defaultValue={values.categoryMediaId}
              {...register("categoryMediaId")}
              className={`px-4 py-[13px] form-control ${
                errors.categoryMediaId ? "is-invalid" : ""
              }`}
              onChange={handleFormData("categoryMediaId")}
            >
              <option value="">Pilih kategori media</option>
              {mediaCategoriesList &&
                mediaCategoriesList.map((x) => (
                  <option value={x.id} key={x.id}>
                    {x.name}
                  </option>
                ))}
            </select>
            <div className="mt-1 invalid-feedback">
              {errors.categoryMediaId?.message}
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <label className="font-bold">Kode Referral</label>
            <input
              type="text"
              name="referralCode"
              defaultValue={values.referralCode}
              {...register("referralCode")}
              className="px-4 py-3 form-control"
              placeholder="Masukkan kode referral (opsional)"
              onChange={handleFormData("referralCode")}
            />
            <div className="mt-1 invalid-feedback"></div>
          </div>
        </div>
        {loading && (
          <p className="mt-2 text-center font-bold capitalize">Loading...</p>
        )}
        {message && (
          <p className="mt-2 text-center text-dark-red font-bold capitalize">
            {message}
          </p>
        )}
        <div className="my-4 flex space-x-4">
          <button
            className="bg-white w-1/2 py-3 rounded-xl text-sm text-primary font-bold border border-primary"
            onClick={prevStep}
          >
            Kembali
          </button>
          <button className="bg-gradient w-1/2 py-3 rounded-xl text-sm text-white font-bold">
            Daftar
          </button>
        </div>
      </form>
    </>
  );
}
export default FormRegister3;
