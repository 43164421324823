import axios from "axios";
import { Routes, Route, Navigate } from "react-router-dom";
import { PublicRoute, PrivateRoute } from "components";
import Login from "pages/Login";
import Forgot from "pages/Forgot";
import Reset from "pages/Reset";
import Register from "pages/Register";
import Verify from "pages/Verify";
import Dashboard from "pages/Dashboard";
import Job from "pages/Job";
import JobDetail from "pages/JobDetail";
import Work from "pages/Work";
import WorkDetail from "pages/WorkDetail";
import Profile from "pages/Profile";
import Address from "pages/Address";
import Password from "pages/Password";
import Rate from "pages/Rate";
import History from "pages/History";
import Withdraw from "pages/Withdraw";
import Review from "pages/Review";
import Notification from "pages/Notification";
import Referral from "pages/Referral";

function App() {
  const responseSuccessHandler = (response) => {
    return response;
  };

  const responseErrorHandler = (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.message.includes("token")
    ) {
      localStorage.clear();
      window.location = "/login";
    }

    return Promise.reject(error);
  };

  axios.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
  );

  const isAuth = localStorage.getItem("isAuth");
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route
        path="/login"
        element={
          <PublicRoute isAuth={isAuth}>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoute isAuth={isAuth}>
            <Forgot />
          </PublicRoute>
        }
      />
      <Route
        path="/password-reset/:token"
        element={
          <PublicRoute isAuth={isAuth}>
            <Reset />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute isAuth={isAuth}>
            <Register />
          </PublicRoute>
        }
      />
      <Route path="/verify/:token" element={<Verify />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/browse-jobs"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Job />
          </PrivateRoute>
        }
      />
      <Route
        path="/browse-jobs/:id/detail"
        element={
          <PrivateRoute isAuth={isAuth}>
            <JobDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/work"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Work />
          </PrivateRoute>
        }
      />
      <Route
        path="/work/:id/detail"
        element={
          <PrivateRoute isAuth={isAuth}>
            <WorkDetail />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/address/edit"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Address />
          </PrivateRoute>
        }
      />
      <Route
        path="/password/edit"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Password />
          </PrivateRoute>
        }
      />
      <Route
        path="/rate/edit"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Rate />
          </PrivateRoute>
        }
      />
      <Route
        path="/history"
        element={
          <PrivateRoute isAuth={isAuth}>
            <History />
          </PrivateRoute>
        }
      />
      <Route
        path="/withdraw"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Withdraw />
          </PrivateRoute>
        }
      />
      <Route
        path="/review"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Review />
          </PrivateRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Notification />
          </PrivateRoute>
        }
      />
      <Route
        path="/referral"
        element={
          <PrivateRoute isAuth={isAuth}>
            <Referral />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
export default App;
