import { useState, useEffect } from "react";
import { getNotifications } from "services/profileService";
import { format, parseISO } from "date-fns";
import indoLocale from "date-fns/locale/id";
import InfiniteScroll from "react-infinite-scroll-component";

function NotificationContentCard() {
  const [notifications, setNotifications] = useState([]);
  const [errorNotifications, setErrorNotifications] = useState(false);
  const filterType = "Semua";
  const [meta, setMeta] = useState({
    total: 0,
    perPage: 10,
    page: 1,
  });
  const [page, setPage] = useState(1);

  const fetchNotifications = async () => {
    let result = {};
    try {
      const response = await getNotifications(page, meta.perPage, filterType);
      if (response.data.length > 0) {
        setNotifications((prevData) => [...prevData, ...response.data]);
      } else {
        setErrorNotifications(true);
      }
      setMeta({
        total: response.meta.total,
        perPage: response.meta.limit,
        page: response.meta.page,
      });
    } catch (error) {
      setErrorNotifications(true);
      throw error;
    }
    return result;
  };

  useEffect(() => {
    fetchNotifications();
  }, [page]);

  return (
    <>
      {notifications?.length > 0 && (
        <InfiniteScroll
          dataLength={notifications.length}
          next={() => setPage((x) => x + 1)}
          hasMore={page * meta.perPage < meta.total}
          className="flex flex-col space-y-4 pb-14"
        >
          {notifications?.length > 0 &&
            notifications.map((data, i) => (
              <div
                key={i}
                className="bg-white rounded-2xl shadow-md border border-border hover:border-primary"
              >
                <div className="p-4">
                  <div className="flex items-center justify-between">
                    <p>{data.message}</p>
                    <p className="text-sm text-text-secondary">
                      {data?.createdAt &&
                        format(parseISO(data?.createdAt), "d MMMM y HH:mm", {
                          locale: indoLocale,
                        })}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </InfiniteScroll>
      )}

      {errorNotifications && (
        <div className="bg-white rounded-2xl border border-border shadow-main">
          <div className="p-8 text-center">
            <p className="font-bold">Tidak ada data notifikasi</p>
          </div>
        </div>
      )}
    </>
  );
}

export default NotificationContentCard;
