import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { forgot } from "services/authService";

function Form() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [emailSend, setEmailSend] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email tidak boleh kosong")
      .email("Format email salah"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmit = (e) => {
    setLoading(true);
    forgot(e.email)
      .then((res) => {
        if (res.success === true) {
          setLoading(false);
          setEmailSend(true);
        }
      })
      .catch((res) => {
        if (res.response.data.success === false) {
          setLoading(false);
          setEmailSend(false);
          setMessage(res.response.data.message);
        }
      });
  };

  return (
    <div className="flex flex-col mt-4 md:mt-0 mb-8 md:mb-0 py-8 md:py-16 px-4 md:w-[410px] md:h-[548px] rounded-2xl md:rounded-none md:rounded-r-2xl bg-white">
      <div className="w-full">
        <h5 className="mb-8 text-2xl font-bold text-center">Lupa Password</h5>
        <p className="mt-8 text-sm text-center">
          Silahkan masukkan email yang terdaftar di PenaKita. Kami akan mengirim
          link untuk mengatur ulang password ke email Anda.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-8 flex flex-col space-y-4 text-sm">
            <div className="flex flex-col space-y-2">
              <label className="font-bold">Email</label>
              <input
                type="text"
                name="email"
                {...register("email")}
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                placeholder="Masukkan email"
                autoComplete="email"
              />
              <div className="invalid-feedback">{errors.email?.message}</div>
            </div>
          </div>
          <div className="mt-px text-center text-sm"></div>
          <button
            type="submit"
            className="mt-8 bg-gradient w-full py-3 rounded-xl text-sm text-white font-bold"
          >
            Submit
          </button>
          <p className="mt-8 text-sm text-center">
            {loading && <span>Loading...</span>}
            {!loading && emailSend ? (
              <>
                <p>Email telah dikirim. Silahkan cek inbox email Anda.</p>
                <p>Belum menerima email?</p>
                <button type="submit" className="text-primary font-bold">
                  Kirim ulang
                </button>
              </>
            ) : (
              <span className="text-dark-red font-bold capitalize">
                {message}
              </span>
            )}
          </p>
        </form>
      </div>
    </div>
  );
}
export default Form;
