export default function MonitorIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 3.51819H4C2.89543 3.51819 2 4.41362 2 5.51819V15.5182C2 16.6228 2.89543 17.5182 4 17.5182H20C21.1046 17.5182 22 16.6228 22 15.5182V5.51819C22 4.41362 21.1046 3.51819 20 3.51819Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 21.5182H16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.5182V21.5182"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
