import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getSummary, getWork } from "services/projectService";
import useReactiveSearchParams from "utils/useReactiveSearchParams";

function WorkCard() {
  const [works, setWorks] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [typeValue, setTypeValue] = useState("Semua");
  const [isActiveType, setIsActiveType] = useState("Semua");
  const [searchParams, setSearchParams] = useReactiveSearchParams();

  const fetchStatusBar = async () => {
    setIsLoading(true);
    let result = {};
    try {
      const response = await getSummary();
      setFilterData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
    return result;
  };

  useEffect(() => {
    const fetchWork = async () => {
      setIsLoading(true);
      let result = {};
      try {
        const response = await getWork(1, 4, "Semua", typeValue);
        if (response.data.length < 1) {
          setIsError(true);
          setIsLoading(false);
        } else {
          setWorks(response.data);
          setIsError(false);
          setIsLoading(false);
        }
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
        throw error;
      }
      return result;
    };
    fetchWork();
  }, [typeValue]);

  useEffect(() => {
    fetchStatusBar();
  }, []);

  const typeStatus = (type) => {
    setIsActiveType(type);
    if (type !== typeValue) {
      setWorks([]);
      setSearchParams({ ...searchParams, type: type });
      setTypeValue(type);
    }
  };

  return (
    <>
      <div className="p-4 flex flex-col space-y-4 h-max bg-white rounded-2xl shadow-main">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-bold">Pekerjaan</h3>
          <Link to="/work">
            <span className="text-primary text-sm font-bold">Lihat Semua</span>
          </Link>
        </div>
        <hr />
        <div className="flex flex-wrap">
          {filterData &&
            filterData.slice(5, 8).map((data, index) => (
              <button
                className={`${
                  isActiveType === data.status
                    ? "border-primary bg-background-orange text-primary"
                    : "border-text-secondary bg-white text-text-secondary"
                } px-4 py-[5px] border text-sm rounded-lg flex items-center space-x-2 mr-2 mb-2 sm:mb-0`}
                key={index}
                id={index}
                onClick={() => typeStatus(data.status)}
              >
                <span>{data.status}</span>
              </button>
            ))}
        </div>
        <div className="flex flex-col space-y-4">
          {isLoading &&
            [0, 0, 0].map((x, i) => (
              <div className="w-full animate-pulse overflow-hidden" key={i}>
                <div className="flex space-x-4">
                  <div className="min-w-[91px] min-h-[91px] rounded-2xl bg-gray-200"></div>
                  <div className="w-full flex flex-col">
                    <div className="w-1/2 p-2 bg-gray-200 rounded"></div>
                    <div className="mt-2 w-1/4 p-2 bg-gray-200 rounded"></div>
                    <div className="mt-2 flex justify-end">
                      <div className="w-1/4 p-2 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {!isError &&
            !isLoading &&
            works.length > 0 &&
            works.map((work) => (
              <div className="flex space-x-4" key={work.projectTransactionId}>
                {work.image ? (
                  <img
                    src={work.image}
                    alt={work.projectTransactionId}
                    className="w-[91px] h-[91px] rounded-2xl object-cover"
                  />
                ) : (
                  <div className="w-[91px] h-[91px] rounded-2xl bg-text-secondary"></div>
                )}
                <div className="w-4/5 flex flex-col space-y-2">
                  <p className="text-sm font-bold leading-4 capitalize">
                    {work.name}
                  </p>
                  <p className="text-sm text-text-secondary leading-4">
                    {work.deadLine}
                  </p>
                  <div className="md:text-right leading-4">
                    <Link to={`/work/${work?.projectTransactionId}/detail`}>
                      <span className="text-sm text-primary font-bold">
                        Lihat detail
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          {isError && (
            <div className="my-24 flex flex-col items-center space-y-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z"
                  stroke="#7A8796"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21"
                  stroke="#7A8796"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-sm text-text-secondary">Belum ada pekerjaan</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default WorkCard;
