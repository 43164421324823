import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  updateProfile,
  fileUpload,
  getMediaTypes,
  getMediaSegmentations,
  getMediaCategories,
} from "services/profileService";
import { Alert } from "components";

function ProfileCard({ profile, refresh, setRefresh }) {
  const phoneRegExp = /^(0)[1-9]\d{7,13}$/;
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Nama perusahaan tidak boleh kosong"),
    companyPhoneNumber: Yup.string()
      .matches(
        phoneRegExp,
        "Harus berawalan angka 0. Minimal 9 digit. Maksimal 15 digit"
      )
      .required("Nomor telepon perusahaan tidak boleh kosong")
      .nullable(),
    companyEmail: Yup.string().required("Email perusahaan tidak boleh kosong"),
    companyWebsite: Yup.string().required(
      "Website perusahaan tidak boleh kosong"
    ),
    picEmail: Yup.string().required("Email pic tidak boleh kosong"),
    picPhoneNumber: Yup.string()
      .matches(
        phoneRegExp,
        "Harus berawalan angka 0. Minimal 9 digit. Maksimal 15 digit"
      )
      .required("Nomor telepon pic tidak boleh kosong")
      .nullable(),
    typeMediaId: Yup.string().required("Jenis media tidak boleh kosong"),
    segmentationMediaId: Yup.string().required(
      "Cakupan media tidak boleh kosong"
    ),
    categoryMediaId: Yup.string().required("Kategori media tidak boleh kosong"),
    description: Yup.string()
      .required("Deskripsi media tidak boleh kosong")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      companyName: "",
      companyPhoneNumber: "",
      companyEmail: "",
      companyWebsite: "",
      picEmail: "",
      picPhoneNumber: "",
      typeMediaId: "",
      segmentationMediaId: "",
      categoryMediaId: "",
      description: "",
    },
  });

  const [typeMediaId, setTypeMediaId] = useState(profile?.typeMediaId);
  const [segmentationMediaId, setSegmentationMediaId] = useState(
    profile?.segmentationMediaId
  );
  const [categoryMediaId, setCategoryMediaId] = useState(
    profile?.categoryMediaId
  );

  useEffect(() => {
    setImage(profile?.companyProfilePicture);
    setTypeMediaId(profile?.typeMediaId);
    setSegmentationMediaId(profile?.segmentationMediaId);
    setCategoryMediaId(profile?.categoryMediaId);
    let defaults = {
      companyName: profile?.companyName,
      companyPhoneNumber: profile?.companyPhoneNumber,
      companyEmail: profile?.companyEmail,
      companyWebsite: profile?.companyWebsite,
      picEmail: profile?.picEmail,
      picPhoneNumber: profile?.picPhoneNumber,
      typeMediaId: profile?.typeMediaId,
      segmentationMediaId: profile?.segmentationMediaId,
      categoryMediaId: profile?.categoryMediaId,
      description: profile?.description,
    };
    reset(defaults);
  }, [profile, reset]);

  // Media types
  const [mediaTypesList, setMediaTypesList] = useState([]);
  const fetchMediaTypes = async () => {
    let result = {};
    const response = await getMediaTypes();
    setMediaTypesList(response);
    return result;
  };

  // Media segmentations
  const [mediaSegmentationsList, setMediaSegmentationsList] = useState([]);
  const fetchMediaSegmentations = async () => {
    let result = {};
    const response = await getMediaSegmentations();
    setMediaSegmentationsList(response);
    return result;
  };

  const [mediaCategoriesList, setMediaCategoriesList] = useState([]);

  const fetchMediaCategories = async () => {
    let result = {};
    const response = await getMediaCategories();
    setMediaCategoriesList(response);
    return result;
  };

  useEffect(() => {
    fetchMediaTypes();
    fetchMediaSegmentations();
    fetchMediaCategories();
  }, []);

  // Handle image preview
  const fileInput = useRef();
  const [image, setImage] = useState("");
  const handleImage = () => {
    if (fileInput.current.files.length > 0) {
      if (fileInput.current.files[0].size > 1048576) {
        window.alert("Gambar tidak boleh lebih dari 1 MB");
      } else {
        setImage(URL.createObjectURL(fileInput.current.files[0]));
      }
    }
  };

  const onSubmit = (e) => {
    // Create with image
    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      formData.append("bucket", "penakita");
      formData.append("file", fileInput.current.files[0]);

      Alert.fire({
        title: "Mengupload gambar...",
      });
      Alert.showLoading();

      return fileUpload(formData)
        .then((res) => {
          Alert.close();
          const imageUri = res.data.url;
          Alert.fire({
            title: "Mengedit profil...",
          });
          Alert.showLoading();
          return updateProfile(e, imageUri)
            .then((res) => {
              if (res) {
                setRefresh(refresh + 1);
                Alert.fire({
                  icon: "success",
                  title: "Sukses",
                  text: "Edit profil berhasil.",
                });
              }
            })
            .catch((res) => {
              if (res.response.status !== 401) {
                Alert.fire({
                  icon: "error",
                  title: "Terjadi kesalahan",
                  text: `Pesan error: ${res.response.data.message}`,
                });
              }
            });
        })
        .catch((error) => {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${error.response.data.message}`,
          });
        });
    }
    // Create without image
    else {
      return updateProfile(e, image)
        .then((res) => {
          if (res) {
            setRefresh(refresh + 1);
            Alert.fire({
              icon: "success",
              title: "Sukses",
              text: "Edit profil berhasil.",
            });
          }
        })
        .catch((res) => {
          if (res.response.status !== 401) {
            Alert.fire({
              icon: "error",
              title: "Terjadi kesalahan",
              text: `Pesan error: ${res.response.data.message}`,
            });
          }
        });
    }
  };

  return (
    <>
      <div className="mt-4 lg:mt-0 w-full lg:max-w-[787px] p-4 flex flex-col space-y-4 bg-white lg:rounded-2xl shadow-main">
        <div className="flex items-center space-x-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" stroke="#243242" strokeWidth="2" />
            <path
              d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 20C18 17.2386 15.3137 15 12 15C8.68629 15 6 17.2386 6 20"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="text-lg font-bold">Edit Profil</h3>
        </div>

        <hr />

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <div className="flex items-center space-x-4">
              {image && (
                <img
                  src={image}
                  alt={profile?.companyName}
                  className="w-16 h-16 rounded-full"
                />
              )}
              {!image && (
                <div className="w-16 h-16 bg-border rounded-full"></div>
              )}

              <label
                htmlFor="image"
                className="px-4 py-2 rounded-xl border border-primary cursor-pointer"
              >
                <span className="text-sm font-bold text-primary">
                  Ganti Logo Perusahaan
                </span>
              </label>
              <input
                type="file"
                id="image"
                name="image"
                className="hidden"
                accept=".jpg, .jpeg, .png"
                ref={fileInput}
                onChange={handleImage}
              />
            </div>
            <div className="my-8 grid md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="text-sm font-bold">Nama Perusahaan</label>
                <input
                  type="text"
                  name="companyName"
                  {...register("companyName")}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.companyName ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan nama perusahaan"
                />
                <div className="mt-1 invalid-feedback">
                  {errors.companyName?.message}
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-bold">
                  Nomor Telepon Perusahaan
                </label>
                <input
                  type="number"
                  name="companyPhoneNumber"
                  {...register("companyPhoneNumber")}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.companyPhoneNumber ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan nomor telepon perusahaan"
                />
                <div className="mt-1 invalid-feedback">
                  {errors.companyPhoneNumber?.message}
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-bold">Email (untuk login)</label>
                <input
                  type="email"
                  name="companyEmail"
                  {...register("companyEmail")}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.companyEmail ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan email"
                />
                <div className="mt-1 invalid-feedback">
                  {errors.companyEmail?.message}
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-bold">Alamat Website</label>
                <input
                  type="text"
                  name="companyWebsite"
                  {...register("companyWebsite")}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.companyWebsite ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan alamat website"
                />
                <div className="mt-1 invalid-feedback">
                  {errors.companyWebsite?.message}
                </div>
              </div>
            </div>

            <hr />

            <div className="my-8 grid md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="text-sm font-bold">Email PIC</label>
                <input
                  type="email"
                  name="picEmail"
                  {...register("picEmail")}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.picEmail ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan email PIC"
                />
                <div className="mt-1 invalid-feedback">
                  {errors.picEmail?.message}
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-bold">Nomor Telepon PIC</label>
                <input
                  type="number"
                  name="picPhoneNumber"
                  {...register("picPhoneNumber")}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.picPhoneNumber ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan nomor telepon pic"
                />
                <div className="mt-1 invalid-feedback">
                  {errors.picPhoneNumber?.message}
                </div>
              </div>
            </div>

            <hr />

            <div className="mt-8 grid md:grid-cols-2 gap-4">
              <div className="grid gap-4">
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Jenis Media</label>
                  <select
                    name="typeMediaId"
                    value={typeMediaId}
                    {...register("typeMediaId")}
                    onChange={(e) => setTypeMediaId(e.target.value)}
                    className={`mt-2 px-4 py-2 text-sm form-control ${
                      errors.typeMediaId ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Pilih jenis media</option>
                    {mediaTypesList &&
                      mediaTypesList.map((x) => (
                        <option value={x.id} key={x.id}>
                          {x.name}
                        </option>
                      ))}
                  </select>
                  <div className="mt-1 invalid-feedback">
                    {errors.typeMediaId?.message}
                  </div>
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Cakupan Media</label>
                  <select
                    name="segmentationMediaId"
                    value={segmentationMediaId}
                    {...register("segmentationMediaId")}
                    onChange={(e) => setSegmentationMediaId(e.target.value)}
                    className={`mt-2 px-4 py-2 text-sm form-control ${
                      errors.segmentationMediaId ? "is-invalid" : ""
                    }`}
                  >
                    <option value="">Pilih cakupan media</option>
                    {mediaSegmentationsList &&
                      mediaSegmentationsList.map((x) => (
                        <option value={x.id} key={x.id}>
                          {x.name}
                        </option>
                      ))}
                  </select>
                  <div className="mt-1 invalid-feedback">
                    {errors.segmentationMediaId?.message}
                  </div>
                </div>
              </div>

              <div>
                <div className="flex flex-col">
                  <label className="text-sm font-bold">Deskripsi Media</label>
                  <textarea
                    name="description"
                    {...register("description")}
                    placeholder="Masukkan deskripsi media"
                    className={`h-[120px] mt-2 px-4 py-2 text-sm resize-none form-control ${
                      errors.description ? "is-invalid" : ""
                    }`}
                  />
                  <div className="mt-1 invalid-feedback">
                    {errors.description?.message}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 mb-8 grid md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="text-sm font-bold">Kategori Media</label>
                <select
                  name="categoryMediaId"
                  value={categoryMediaId}
                  {...register("categoryMediaId")}
                  onChange={(e) => setCategoryMediaId(e.target.value)}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.categoryMediaId ? "is-invalid" : ""
                  }`}
                >
                  <option value="">Pilih kategori media</option>
                  {mediaCategoriesList &&
                    mediaCategoriesList.map((x) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                </select>
                <div className="mt-1 invalid-feedback">
                  {errors.categoryMediaId?.message}
                </div>
              </div>

              <div className="flex justify-end self-end h-10">
                <button
                  type="submit"
                  className="w-full lg:w-auto lg:px-16 bg-primary text-white text-sm font-bold rounded-xl"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ProfileCard;
