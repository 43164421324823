import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { changePassword } from "services/profileService";
import { Alert } from "components";
import { EyeIcon, EyeOffIcon } from "assets";

function PasswordCard() {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Password lama tidak boleh kosong"),
    password: Yup.string()
      .required("Password baru tidak boleh kosong")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,}$/,
        "Password harus terdiri dari huruf besar, huruf kecil, angka dan simbol. Minimal 6 karakter atau lebih."
      ),
    passwordConfirmation: Yup.string()
      .required("Konfirmasi password tidak boleh kosong")
      .oneOf([Yup.ref("password"), null], "Konfirmasi password harus sama"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      oldPassword: "",
      password: "",
      passwordConfirmation: "",
    },
  });

  const onSubmit = (e) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengganti password akun!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Konfirmasi",
    }).then((result) => {
      if (result.isConfirmed) {
        changePassword(e.oldPassword, e.password, e.passwordConfirmation)
          .then((res) => {
            if (res) {
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Password berhasil diganti.",
              });
              reset();
            }
          })
          .catch((res) => {
            if (res.response.status !== 401) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${res.response.data.message}`,
              });
            }
          });
      }
    });
  };

  return (
    <>
      <div className="mt-4 lg:mt-0 w-full lg:max-w-[787px] h-max p-4 flex flex-col space-y-4 bg-white lg:rounded-2xl shadow-main">
        <div className="flex items-center space-x-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.5 7.5L19 4M21 2L19 4L21 2ZM11.39 11.61C11.9063 12.1195 12.3168 12.726 12.5978 13.3948C12.8787 14.0635 13.0246 14.7813 13.027 15.5066C13.0295 16.232 12.8884 16.9507 12.6119 17.6213C12.3354 18.2919 11.9291 18.9012 11.4161 19.4141C10.9032 19.9271 10.2939 20.3334 9.6233 20.6099C8.95268 20.8864 8.234 21.0275 7.50863 21.025C6.78327 21.0226 6.06554 20.8767 5.39679 20.5958C4.72804 20.3148 4.12147 19.9043 3.612 19.388C2.61013 18.3507 2.05576 16.9614 2.06829 15.5193C2.08082 14.0772 2.65925 12.6977 3.679 11.678C4.69874 10.6583 6.07821 10.0798 7.52029 10.0673C8.96238 10.0548 10.3517 10.6091 11.389 11.611L11.39 11.61ZM11.39 11.61L15.5 7.5L11.39 11.61ZM15.5 7.5L18.5 10.5L22 7L19 4L15.5 7.5Z"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="text-lg font-bold">Ganti Password</h3>
        </div>
        <hr />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-2">
            <div className="w-full lg:w-1/2 flex flex-col">
              <div className="flex flex-col space-y-2 relative">
                <label className="text-sm font-bold">Password Lama</label>
                <input
                  type={`${showOldPassword ? "text" : "password"}`}
                  name="oldPassword"
                  {...register("oldPassword")}
                  className={`form-control pr-10 ${
                    errors.oldPassword ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan password lama"
                  autoComplete="current-password"
                />
                <div
                  className="absolute top-[35%] right-3 text-gray-400"
                  onClick={() => setShowOldPassword((prevState) => !prevState)}
                >
                  {showOldPassword ? <EyeOffIcon /> : <EyeIcon />}
                </div>
                {errors.oldPassword ? (
                  <div className="invalid-feedback">
                    {errors.oldPassword?.message}
                  </div>
                ) : (
                  <div className="pt-4"></div>
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col">
              <div className="flex flex-col space-y-2 relative">
                <label className="text-sm font-bold">Password Baru</label>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  {...register("password")}
                  className={`form-control pr-10 ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan password baru"
                  autoComplete="new-password"
                />
                <div
                  className="absolute top-9 right-3 text-gray-400"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                >
                  {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                </div>
                {errors.password ? (
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                ) : (
                  <div className="pt-4"></div>
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-col">
              <div className="flex flex-col space-y-2 relative">
                <label className="text-sm font-bold">
                  Ulangi Password Baru
                </label>
                <input
                  type={`${showPasswordConfirmation ? "text" : "password"}`}
                  name="passwordConfirmation"
                  {...register("passwordConfirmation")}
                  className={`form-control pr-10 ${
                    errors.passwordConfirmation ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan ulang password baru"
                  autoComplete="new-password"
                />
                <div
                  className="absolute top-9 right-3 text-gray-400"
                  onClick={() =>
                    setShowPasswordConfirmation((prevState) => !prevState)
                  }
                >
                  {showPasswordConfirmation ? <EyeOffIcon /> : <EyeIcon />}
                </div>
                {errors.passwordConfirmation ? (
                  <div className="invalid-feedback">
                    {errors.passwordConfirmation?.message}
                  </div>
                ) : (
                  <div className="pt-4"></div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 w-full lg:w-auto lg:px-16 py-[10px] bg-gradient text-white text-sm font-bold rounded-xl"
            >
              Simpan
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default PasswordCard;
