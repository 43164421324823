import { useContext } from "react";
import { Layout, Sidebar, Nav, Header, Main, ProfileMenu, ProfileNav } from "components";
import Title from "components/Title";
import WithdrawSection from "./components/WithdrawSection";
import WithdrawCard from "./components/WithdrawCard";
import { ProfileContext } from "contexts/ProfileContext";

function Withdraw() {
  const { profilesData, isLoading, refresh, setRefresh } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Profile" />
          <WithdrawSection>
            <ProfileMenu>
              <ProfileNav profile={profilesData} />
            </ProfileMenu>
            <WithdrawCard profile={profilesData} isLoading={isLoading} refresh={refresh} setRefresh={setRefresh} />
          </WithdrawSection>
        </Main>
      </Layout>
    </>
  );
}

export default Withdraw;
