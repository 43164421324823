import axios from "axios";

async function login(email, password) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/v1/login`,
      {
        email,
        password,
      },
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    if (response) {
      localStorage.setItem("profileData", JSON.stringify(response.data.data));
      localStorage.setItem("isAuth", true);
    }
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function forgot(email) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/v1/forgot-password`,
      {
        email,
      },
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function reset(password, passwordConfirmation, token) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/v1/reset-password`,
      {
        password,
        passwordConfirmation,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function verify(token) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/v1/confirmation`,
      {
        token,
      },
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function registration({
  companyEmail,
  password,
  passwordConfirmation,
  companyName,
  companyWebsite,
  picEmail,
  categoryMediaId,
  typeMediaId,
  segmentationMediaId,
  referralCode,
}) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/v1/register`,
      {
        companyEmail,
        password,
        passwordConfirmation,
        companyName,
        companyWebsite,
        picEmail,
        categoryMediaId,
        typeMediaId,
        segmentationMediaId,
        referralCode,
      },
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    if (response) {
      localStorage.setItem("profileData", JSON.stringify(response.data.data));
      localStorage.setItem("isAuth", true);
    }
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

export { login, forgot, reset, verify, registration };
