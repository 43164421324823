export default function authHeader() {
  const data = JSON.parse(localStorage.getItem("profileData"));
  const token = data?.token;

  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}
