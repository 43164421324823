import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

function FormRegister2({ nextStep, handleFormData, prevStep, values }) {
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Nama media tidak boleh kosong"),
    companyWebsite: Yup.string().required("Website tidak boleh kosong"),
    picEmail: Yup.string()
      .required("Email PIC tidak boleh kosong")
      .email("Format email salah"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const submitFormData = () => {
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit(submitFormData)}>
      <div className="mt-8 flex flex-col space-y-4 text-sm">
        <div className="flex flex-col space-y-2">
          <label className="font-bold">Nama Media</label>
          <input
            type="text"
            name="companyName"
            defaultValue={values.companyName}
            {...register("companyName")}
            className={`px-4 py-3 form-control ${
              errors.companyName ? "is-invalid" : ""
            }`}
            placeholder="Masukkan nama media"
            onChange={handleFormData("companyName")}
          />
          <div className="mt-1 invalid-feedback">
            {errors.companyName?.message}
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <label className="font-bold">Website</label>
          <input
            type="url"
            name="companyWebsite"
            defaultValue={values.companyWebsite}
            {...register("companyWebsite")}
            className={`px-4 py-3 form-control ${
              errors.companyWebsite ? "is-invalid" : ""
            }`}
            placeholder="Masukkan website media"
            onChange={handleFormData("companyWebsite")}
          />
          <div className="mt-1 invalid-feedback">
            {errors.companyWebsite?.message}
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <label className="font-bold">Email PIC (untuk verifikasi)</label>
          <input
            type="email"
            name="picEmail"
            defaultValue={values.picEmail}
            {...register("picEmail")}
            className={`px-4 py-3 form-control ${
              errors.picEmail ? "is-invalid" : ""
            }`}
            placeholder="Masukkan email PIC"
            onChange={handleFormData("picEmail")}
          />
          <div className="mt-1 invalid-feedback">
            {errors.picEmail?.message}
          </div>
        </div>
      </div>
      <div className="my-8 flex space-x-4">
        <button
          className="bg-white w-1/2 py-3 rounded-xl text-sm text-primary font-bold border border-primary"
          onClick={prevStep}
        >
          Kembali
        </button>
        <button
          className="bg-gradient w-1/2 py-3 rounded-xl text-sm text-white font-bold"
          type="submit"
        >
          Selanjutnya
        </button>
      </div>
    </form>
  );
}
export default FormRegister2;
