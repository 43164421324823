import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import indoLocale from "date-fns/locale/id";
import { BarIcon, TwoUserIcon, UserCheckIcon, logo, account } from "assets";
import { ErrorCard } from "components";
import { currency } from "utils";

function JobContentCard({ project, noProject, isError }) {
  return (
    <>
      {!isError && project && (
        <div
          key={project.projectId}
          className="bg-white rounded-2xl border border-border shadow-main"
        >
          <div className="p-4 flex flex-col md:flex-row md:space-x-4">
            <div className="hidden md:block min-w-max">
              <img
                src={project?.image ?? logo}
                alt={project?.name}
                className="rounded-2xl w-[123px] h-[123px] object-cover"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = logo;
                }}
              />
            </div>
            <div className="flex flex-col space-y-4 w-full">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <img
                    src={project?.createdBy?.profilePicture ?? account}
                    alt={project?.projectId}
                    className="w-6 h-6 object-cover"
                    onError={(e) => {
                      e.currentTarget.onerror = null;
                      e.currentTarget.src = account;
                    }}
                  />
                  <span className="text-sm text-text-secondary">
                    {project?.createdBy?.companyName}
                  </span>
                </div>
                <div className="text-sm">
                  <span className="hidden md:inline-block font-bold">
                    Dibuat pada
                  </span>
                  <span className="text-text-secondary">
                    {" "}
                    {format(parseISO(project?.createdAt), "d MMMM y", {
                      locale: indoLocale,
                    })}
                  </span>
                </div>
              </div>
              <div className="md:flex md:space-x-8">
                <div className="flex md:flex-col justify-between w-full md:w-9/12">
                  <div className="flex flex-col space-y-4">
                    <h3 className="text-sm md:text-lg font-bold capitalize">
                      {project?.name}
                    </h3>
                    <p className="hidden md:block text-sm text-text-secondary">
                      {project?.description?.length > 230
                        ? project.description.slice(0, 230) + "..."
                        : project?.description}
                    </p>
                  </div>
                  <div className="block md:hidden min-w-max">
                    {project?.image ? (
                      <img
                        src={project?.image}
                        alt={project?.name}
                        className="rounded-2xl w-16 md:w-[123px] h-16 md:h-[123px] object-cover"
                      />
                    ) : (
                      <div className="bg-text-secondary rounded-2xl w-16 md:w-[123px] h-16 md:h-[123px]"></div>
                    )}
                  </div>
                  <div className="hidden md:block">
                    <Link to={`${project?.projectId}/detail`}>
                      <button className="mt-1 px-3 py-[9px] w-[170px] rounded-xl text-primary border border-primary text-sm font-bold">
                        Lihat Detail Pekerjaan
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="hidden md:block border border-border"></div>
                <div className="mt-4 md:mt-0 flex flex-wrap md:flex-col md:space-y-4 md:w-3/12">
                  <div className="mr-8 md:mr-0 mb-4 md:mb-0 flex space-x-4 text-text-secondary">
                    <BarIcon />
                    <div className="flex flex-col space-y-2 w-max">
                      <span className="text-sm">Budget Total</span>
                      <span className="text-sm text-text-primary font-bold">
                        {currency(project?.totalBudget)}
                      </span>
                    </div>
                  </div>
                  <div className="mr-8 md:mr-0 mb-4 md:mb-0 flex space-x-4 text-text-secondary">
                    <TwoUserIcon />
                    <div className="flex flex-col space-y-2 w-max">
                      <span className="text-sm">Media Pitching</span>
                      <span className="text-sm text-text-primary font-bold">
                        {project?.totalMediaPitching}
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-4 text-text-secondary">
                    <UserCheckIcon />
                    <div className="flex flex-col space-y-2 w-max">
                      <span className="text-sm whitespace-nowrap">
                        Media Sudah Diterima
                      </span>
                      <span className="text-sm text-text-primary font-bold">
                        {project?.totalMediaApproved}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="block md:hidden">
                  <Link to={`${project?.projectId}/detail`}>
                    <button className="mt-4 px-3 py-[9px] w-full md:w-[170px] rounded-xl text-primary border border-primary text-sm font-bold">
                      Lihat Detail Pekerjaan
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {(isError || noProject) && <ErrorCard text="Tidak ada data rilis" />}
    </>
  );
}

export default JobContentCard;
