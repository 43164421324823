import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { postEvidence } from "services/projectService";
import { Alert } from "components";
import { LinkIcon } from "assets";

function EvidenceCard({
  evidence,
  title,
  params,
  reloadPage,
  isDone,
  isExpired,
  isWaiting,
  isReject,
  status,
}) {
  const validationSchema = Yup.object().shape({
    postLink: Yup.string()
      .required("Link bukti publikasi tidak boleh kosong")
      .nullable(),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      postLink: evidence,
    },
  });

  useEffect(() => {
    let defaults = {
      postLink: evidence,
    };
    reset(defaults);
  }, [evidence, reset]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeLink, setChangeLink] = useState(evidence);

  const onSubmit = (e) => {
    postEvidence(params, e.postLink)
      .then((res) => {
        if (res) {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Link bukti publikasi berhasil dikirim.",
          });
          setIsModalOpen(false);
          reloadPage();
          reset();
        }
      })
      .catch((res) => {
        if (res.response.status !== 401) {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${res.response.data.message}`,
          });
        }
      });
  };

  const closeModal = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  const [projectStatus, setProjectStatus] = useState("");
  useEffect(() => {
    switch (status) {
      case "Telah Selesai":
        setProjectStatus("Selesai");
        break;
      case "Ditolak":
        setProjectStatus("Telah ditolak");
        break;
      default:
        setProjectStatus("Kirim Bukti Publikasi");
        break;
    }
  }, [status]);

  return (
    <>
      <div className="mb-4 p-4 flex flex-col space-y-4 bg-white rounded-2xl shadow-main">
        <h3 className="text-lg font-bold">Kirim Bukti</h3>
        <hr />
        <p className="text-text-secondary text-sm">
          Silahkan masukkan link sebagai bukti bahwa rilis telah dipublikasikan
          di media Anda.
        </p>
        <div>
          <label className="font-bold text-sm">Link Bukti</label>
          <div className="relative">
            <input
              type="text"
              name="postLink"
              {...register("postLink")}
              className={`${
                (isDone || isExpired || isWaiting || isReject) &&
                "text-text-secondary bg-disabled"
              } mt-1 w-full pl-4 pr-16 py-[9px] text-sm form-control ${
                errors.postLink ? "is-invalid" : ""
              }`}
              placeholder="Masukkan link bukti publikasi"
              onChange={(e) => setChangeLink(e.target.value)}
              disabled={isDone || isExpired || isWaiting || isReject}
            />
            {(isDone || isExpired || isWaiting) && (
              <a href={evidence} target="_blank" rel="noopener noreferrer">
                <div className="absolute inset-y-0 right-4 flex items-center text-text-secondary">
                  <LinkIcon />
                </div>
              </a>
            )}
            <div className="mt-1 invalid-feedback">
              {errors.postLink?.message}
            </div>
          </div>
        </div>
        <button
          className={`${
            isDone || isExpired || isWaiting || isReject
              ? "bg-text-secondary cursor-not-allowed"
              : "bg-gradient"
          } py-[10px] rounded-xl text-sm font-bold text-white`}
          onClick={() => setIsModalOpen(true)}
          disabled={isDone || isExpired || isWaiting || isReject}
        >
          {projectStatus}
        </button>
      </div>

      {/* Modal */}
      <div
        className={`${
          isModalOpen ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-8 w-full flex flex-col"
          >
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.3334 6.66669L36.6667 43.3334"
                stroke="url(#paint0_linear_132_9284)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M73.3334 6.66669L50.0001 73.3334L36.6667 43.3334L6.66675 30L73.3334 6.66669Z"
                stroke="url(#paint1_linear_132_9284)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_132_9284"
                  x1="55.0001"
                  y1="6.66669"
                  x2="55.0001"
                  y2="43.3334"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_132_9284"
                  x1="40.0001"
                  y1="6.66669"
                  x2="40.0001"
                  y2="73.3334"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
              </defs>
            </svg>

            <h1 className="mt-4 text-2xl font-bold">Kirim Bukti Publikasi</h1>
            <p className="mt-2 text-sm text-text-secondary">
              Anda akan mengirim bukti publikasi dengan detail:
            </p>
            <div className="mt-3 p-4 rounded-2xl bg-background border border-border">
              <p className="mb-2 text-sm text-text-secondary">Judul Rilis</p>
              <p className="mb-4 font-bold text-text-primary capitalize">
                {title}
              </p>
              <p className="mb-2 text-sm text-text-secondary">
                Bukti Publikasi
              </p>
              <p className="font-bold text-primary break-all">{changeLink}</p>
            </div>

            <p className="mt-3 text-sm text-text-secondary">
              Setelah Anda mengirim bukti publikasi, pekerjaan Anda akan berubah
              status menjadi{" "}
              <span className="font-bold text-text-primary">
                “Menunggu Konfirmasi”
              </span>{" "}
              dan akan dikonfirmasi oleh klien.
            </p>

            <div className="mt-4 flex justify-between space-x-4">
              <button
                className="w-full px-4 py-2 font-bold bg-white text-primary border border-primary rounded-xl ease-in-out duration-200 hover:shadow-lg"
                onClick={(e) => closeModal(e)}
              >
                Batal
              </button>
              <button className="w-full px-4 py-2 font-bold bg-gradient text-white rounded-xl ease-in-out duration-200 hover:shadow-lg">
                Kirim
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EvidenceCard;
