import { ChevronRightIcon } from "assets";
import { useNavigate } from "react-router-dom";

function BreadCrumb({ root, title }) {
  const navigate = useNavigate();

  return (
    <div className="mt-4 px-4 xl:px-0 flex items-center text-text-secondary space-x-2">
      <button
        className="text-sm text-primary hover:underline"
        onClick={() => navigate(-1)}
      >
        {root}
      </button>
      <ChevronRightIcon />
      <span className="text-sm text-text-primary capitalize">{title}</span>
    </div>
  );
}

export default BreadCrumb;
