import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { MonitorIcon, WorkIcon, SearchIcon, logo } from "assets";

function Nav({ className, image }) {
  const [isLoading, setIsLoading] = useState(false);

  const menu = [
    {
      title: "Dashboard",
      icon: <MonitorIcon />,
      selected: true,
      to: "/dashboard",
    },
    {
      title: "Ambil Rilis",
      icon: <SearchIcon />,
      selected: false,
      to: "/browse-jobs",
    },
    {
      title: "Pekerjaan",
      icon: <WorkIcon />,
      selected: false,
      to: "/work",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    if (image !== undefined) {
      setIsLoading(false);
    }
  }, [image]);

  return (
    <nav
      className={`xl:h-[720px] p-4 rounded-2xl bg-white shadow-main xl:sticky xl:top-0 xl:z-40 ${className}`}
    >
      {isLoading && (
        <div className="w-full flex justify-center animate-pulse overflow-hidden">
          <div className="w-[120px] h-[120px] rounded-2xl bg-gray-200"></div>
        </div>
      )}
      {!isLoading && !image && (
        <img
          src={logo}
          alt="logo"
          className="w-16 h-16 xl:w-[120px] xl:h-[120px] mx-auto"
        />
      )}
      {!isLoading && image && (
        <img
          src={image}
          alt="logo"
          className="w-16 h-16 xl:w-[120px] xl:h-[120px] mx-auto object-cover"
        />
      )}
      <ul className="mt-4 flex flex-col space-y-2">
        {menu.map((item, i) => (
          <li
            className="overflow-hidden overflow-ellipsis whitespace-nowrap"
            key={i}
          >
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                `flex items-center px-4 py-3 rounded-2xl hover:bg-border ${
                  isActive ? "bg-background-orange font-bold text-primary" : ""
                }`
              }
            >
              <span className="flex-shrink-0 mr-3">{item.icon}</span>
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}
export default Nav;
