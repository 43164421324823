import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { Layout, Sidebar, Nav, Header, Main } from "components";
import Title from "components/Title";
import NotificationSection from "./components/NotificationSection";
import NotificationContentCard from "./components/NotificationContentCard";

function Notification() {
  const { profilesData } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Notifikasi" />
          <NotificationSection>
            <NotificationContentCard />
          </NotificationSection>
        </Main>
      </Layout>
    </>
  );
}

export default Notification;
