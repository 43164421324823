import { createContext, useEffect, useState } from "react";
import { getProfile } from "services/profileService";

export const ProfileContext = createContext();

const ProfileContextProvider = (props) => {
  const [profilesData, setProfilesData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const getProfilesData = async () => {
    setIsLoading(true);
    try {
      setProfilesData(await getProfile());
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfilesData();
  }, [refresh])

  return (
    <ProfileContext.Provider value={{ profilesData, isLoading, isError, refresh, setRefresh }}>
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;
