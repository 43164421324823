import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import { Layout, Sidebar, Nav, Header, Main, ProfileMenu, ProfileNav } from "components";
import Title from "components/Title";
import PasswordSection from "./components/PasswordSection";
import PasswordCard from "./components/PasswordCard";

function Password() {
  const { profilesData } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Profile" />
          <PasswordSection>
            <ProfileMenu>
              <ProfileNav profile={profilesData} />
            </ProfileMenu>
            <PasswordCard />
          </PasswordSection>
        </Main>
      </Layout>
    </>
  );
}

export default Password;
