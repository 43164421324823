import axios from "axios";
import authHeader from "./authHeader";

async function fileUpload(formData) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_CLIENT_URL}/uploads/v1/upload`,
      formData,
      {
        auth: {
          username: process.env.REACT_APP_CLIENT_USERNAME,
          password: process.env.REACT_APP_CLIENT_PASSWORD,
        },
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getProvince() {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/open-api/v1/provinces`,
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    result = response.data.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getCity(provinceId) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/open-api/v1/cities/${provinceId}`,
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    result = response.data.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getBank() {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/open-api/v1/banks`,
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    result = response.data.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getMediaTypes() {
  let result = {};
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/open-api/v1/media-types`,
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    result = response.data.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }
  return result;
}

async function getMediaSegmentations() {
  let result = {};
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/open-api/v1/media-segmentations`,
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    result = response.data.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }
  return result;
}

async function getMediaCategories() {
  let result = {};
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/open-api/v1/media-categories`,
      {
        auth: {
          username: process.env.REACT_APP_AUTH_USERNAME,
          password: process.env.REACT_APP_AUTH_PASSWORD,
        },
      }
    );
    result = response.data.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }
  return result;
}

async function getProfile() {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profile/v1/me`,
      {
        headers: authHeader(),
      }
    );
    result = response.data.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function updateProfile(
  {
    companyName,
    companyPhoneNumber,
    companyEmail,
    companyWebsite,
    picEmail,
    picPhoneNumber,
    typeMediaId,
    segmentationMediaId,
    categoryMediaId,
    description,
  },
  companyProfilePicture
) {
  let result = {};

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/profile/v1/me`,
      {
        companyName,
        companyPhoneNumber,
        companyEmail,
        companyWebsite,
        picEmail,
        picPhoneNumber,
        typeMediaId,
        segmentationMediaId,
        categoryMediaId,
        description,
        companyProfilePicture,
      },
      {
        headers: authHeader(),
      }
    );
    result = response.data.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function changePassword(oldPassword, password, passwordConfirmation) {
  let result = {};

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/auth/v1/change-password`,
      {
        oldPassword,
        password,
        passwordConfirmation,
      },
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function updateRate(ratePublisherBrandContent) {
  let result = {};

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/profile/v1/rate-publisher-brand-content`,
      {
        ratePublisherBrandContent,
      },
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function updateRateSme(rateSme) {
  let result = {};

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/profile/v1/rate-sme`,
      {
        rateSme,
      },
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getHistoryRate(page, limit) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profile/v1/rate-publisher-brand-content?page=${page}&limit=${limit}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function updateAddress(provinceId, cityId, address) {
  let result = {};

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/profile/v1/address`,
      {
        provinceId,
        cityId,
        address,
      },
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function updateBank(bankAccountNumber, bankId, bankAccountName) {
  let result = {};

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/profile/v1/bank`,
      {
        bankAccountNumber,
        bankId,
        bankAccountName,
      },
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function withdraw(totalAmount) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/finances/v1/withdraw-request`,
      {
        totalAmount,
      },
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getHistoryFinance(page, limit) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/finances/v1/history?page=${page}&limit=${limit}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getReview(page, limit) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/reviews/v1/history?page=${page}&limit=${limit}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getNotifications(page, limit, type) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications/v1/history?page=${page}&limit=${limit}&type=${type}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getAnnouncement(page, limit, type) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/announcements/v1/announcement?page=${page}&limit=${limit}&type=${type}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getDownline(page, limit, keyword) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/media/v1/list?page=${page}&limit=${limit}&keyword=${keyword}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function generateCode(id) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/media/v1/referralcode/${id}`,
      {},
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

export {
  fileUpload,
  getProvince,
  getCity,
  getBank,
  getMediaTypes,
  getMediaSegmentations,
  getMediaCategories,
  getProfile,
  updateProfile,
  changePassword,
  updateRate,
  updateRateSme,
  getHistoryRate,
  updateAddress,
  updateBank,
  withdraw,
  getHistoryFinance,
  getReview,
  getNotifications,
  getAnnouncement,
  getDownline,
  generateCode,
};
