import { useState, useEffect } from "react";
import { getReview } from "services/profileService";
import InfiniteScroll from "react-infinite-scroll-component";
import ReviewCard from "./ReviewCard";

function ReviewContent() {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [meta, setMeta] = useState({
    total: 0,
    perPage: 10,
    page: 1,
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchReview = async () => {
      let result = {};
      try {
        const response = await getReview(page, meta.perPage);
        if (response.data.length) {
          setReviews((prevData) => [...prevData, ...response.data]);
        } else {
          setError(true);
        }
        setLoading(false);
        setMeta({
          total: response.meta.total,
          perPage: response.meta.limit,
          page: response.meta.page,
        });
      } catch (error) {
        setLoading(false);
        setError(true);
        throw error;
      }
      return result;
    };
    fetchReview();
  }, [page, meta.perPage]);

  return (
    <>
      <div className="mt-4 lg:mt-0 w-full lg:max-w-[787px] h-max p-4 flex flex-col space-y-4 bg-white lg:rounded-2xl shadow-main">
        <div className="flex items-center space-x-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="text-lg font-bold">Review Klien</h3>
        </div>
        <hr />
        <div className="py-4 flex flex-col space-y-8">
          <InfiniteScroll
            dataLength={reviews.length}
            next={() => setPage((x) => x + 1)}
            hasMore={page * meta.perPage < meta.total}
            className="flex flex-col space-y-4"
          >
            {loading &&
              [0, 0, 0, 0, 0].map((_, i) => (
                <div key={i}>
                  <div className="pb-4 w-full animate-pulse overflow-hidden">
                    <div className="flex space-x-4">
                      <div className="w-6 h-6 rounded-2xl bg-gray-200"></div>
                      <div className="w-full flex flex-col space-y-2">
                        <div className="p-2 w-1/5 bg-gray-200 rounded"></div>
                        <div className="p-2 w-1/6 bg-gray-200 rounded"></div>
                        <div className="p-2 w-1/3 bg-gray-200 rounded"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {!error &&
              !loading &&
              reviews.length > 0 &&
              reviews.map((data, i) => <ReviewCard key={i} review={data} />)}
            {error && (
              <div className="text-center font-bold">
                Tidak ada data review klien
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
}

export default ReviewContent;
