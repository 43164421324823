import { useState, useEffect } from "react";
import { getOverview } from "services/projectService";
import { currency } from "utils";

function OverviewCard() {
  const [overview, setOverview] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchOverview = async () => {
    setIsLoading(true);
    let result = {};
    try {
      const response = await getOverview();
      setOverview(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
    return result;
  };

  useEffect(() => {
    fetchOverview();
  }, []);

  return (
    <>
      <div className="p-4 flex flex-col space-y-4 bg-white rounded-2xl shadow-main">
        <h3 className="text-lg font-bold">Overview</h3>
        <hr />
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="p-4 flex flex-col lg:flex-row lg:items-center lg:justify-between rounded-2xl bg-background-orange border border-primary shadow-main">
            <p className="w-1/2 text-xs text-text-secondary">
              Pekerjaan Berlangsung
            </p>
            <div className="pt-4 lg:pt-0 text-2xl font-bold text-primary">
              {!isLoading && overview?.totalProject}
              {isLoading && (
                <div className="mt-2 w-8 animate-pulse overflow-hidden">
                  <div className="w-full p-3 bg-gray-200 rounded"></div>
                </div>
              )}
            </div>
          </div>
          <div className="p-4 flex flex-col lg:flex-row lg:items-center lg:justify-between rounded-2xl bg-background-orange border border-primary shadow-main">
            <p className="w-1/2 text-xs text-text-secondary">
              Penawaran Pekerjaan
            </p>
            <div className="pt-4 lg:pt-0 text-2xl font-bold text-primary">
              {!isLoading && overview?.totalTunjukLangsung}
              {isLoading && (
                <div className="mt-2 w-8 animate-pulse overflow-hidden">
                  <div className="w-full p-3 bg-gray-200 rounded"></div>
                </div>
              )}
            </div>
          </div>
          <div className="p-4 flex flex-col lg:flex-row lg:items-center lg:justify-between rounded-2xl bg-background-orange border border-primary shadow-main">
            <p className="w-1/2 text-xs text-text-secondary">
              Pitch Berlangsung
            </p>
            <div className="pt-4 lg:pt-0 text-2xl font-bold text-primary">
              {!isLoading && overview?.totalPitch}
              {isLoading && (
                <div className="mt-2 w-8 animate-pulse overflow-hidden">
                  <div className="w-full p-3 bg-gray-200 rounded"></div>
                </div>
              )}
            </div>
          </div>
          <div className="p-4 flex lg:hidden flex-col lg:flex-row lg:items-center lg:justify-between rounded-2xl bg-background-orange border border-primary shadow-main">
            <p className="w-1/2 text-xs text-text-secondary">Rating Media</p>
            <div className="pt-4 lg:pt-0 text-2xl font-bold text-primary">
              {!isLoading && overview?.rating}
              {isLoading && (
                <div className="mt-2 w-8 animate-pulse overflow-hidden">
                  <div className="w-full p-3 bg-gray-200 rounded"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 gap-4">
          <div className="col-span-2 p-4 flex flex-col lg:flex-row lg:items-center lg:justify-between rounded-2xl bg-background-orange border border-primary shadow-main">
            <p className="w-full lg:w-1/4 text-xs text-text-secondary">
              Penghasilan Bulan Ini
            </p>
            <div className="pt-4 lg:pt-0 text-2xl font-bold text-primary">
              {!isLoading && currency(overview?.income)}
              {isLoading && (
                <div className="mt-2 w-28 animate-pulse overflow-hidden">
                  <div className="w-full p-3 bg-gray-200 rounded"></div>
                </div>
              )}
            </div>
          </div>
          <div className="hidden lg:flex p-4 items-center justify-between rounded-2xl bg-background-orange border border-primary shadow-main">
            <p className="w-1/2 text-xs text-text-secondary">Rating Media</p>
            <div className="text-2xl font-bold text-primary">
              {!isLoading && overview?.rating}
              {isLoading && (
                <div className="mt-2 w-8 animate-pulse overflow-hidden">
                  <div className="w-full p-3 bg-gray-200 rounded"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OverviewCard;
