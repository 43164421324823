import { useState, useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { updateRate } from "services/profileService";
import { Layout, Sidebar, Nav, Header, Main, Alert } from "components";
import Title from "components/Title";
import DashboardSection from "./components/DasboardSection";
import OverviewCard from "./components/OverviewCard";
import NotificationCard from "./components/NotificationCard";
import AnnouncementCard from "./components/AnnouncementCard";
import WorkCard from "./components/WorkCard";
import { ProfileContext } from "contexts/ProfileContext";
import { NumericFormat } from "react-number-format";

function Dashboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { profilesData } = useContext(ProfileContext);

  useEffect(() => {
    if (profilesData) {
      profilesData.ratePublisherBrandContent > 0
        ? setIsModalOpen(false)
        : setIsModalOpen(true);
    }
  }, [profilesData]);

  const validationSchema = Yup.object().shape({
    ratePublisherBrandContent: Yup.string().required(
      "Rate publikasi brand content tidak boleh kosong"
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });

  const onSubmit = (e) => {
    updateRate(e.ratePublisherBrandContent)
      .then((res) => {
        if (res.success === true) {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: "Ubah rate publikasi brand content berhasil.",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      })
      .catch((res) => {
        if (res.response.data.success === false) {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${res.response.data.message}`,
          });
        }
      });
  };

  const closeModal = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Modal */}
      <div
        className={`${
          isModalOpen ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-8 w-full flex flex-col"
          >
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.3751 19.1876C28.6279 17.7592 28.4614 16.0973 28.9106 14.5492C29.3597 13.001 30.3897 11.6862 31.7853 10.8795C33.1809 10.0728 34.8343 9.83648 36.4 10.22C37.9658 10.6035 39.3228 11.5772 40.1876 12.9376L49.5626 29.1563"
                stroke="url(#paint0_linear_72_2689)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.0626 29.7501L16.0626 21.0938C15.3154 19.6654 15.1489 18.0036 15.5981 16.4554C16.0472 14.9072 17.0772 13.5925 18.4728 12.7857C19.8684 11.979 21.5218 11.7427 23.0875 12.1262C24.6533 12.5098 26.0103 13.4834 26.8751 14.8438L37.5001 33.2501"
                stroke="url(#paint1_linear_72_2689)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M48.1252 51.6564C47.3017 50.2372 46.7665 48.6694 46.5502 47.0429C46.3339 45.4164 46.4407 43.7632 46.8646 42.1781C47.2884 40.593 48.021 39.1071 49.0202 37.8057C50.0195 36.5042 51.2658 35.4128 52.6877 34.5939L49.5627 29.1564C48.7339 27.7185 48.5102 26.0102 48.941 24.4073C49.3717 22.8045 50.4216 21.4384 51.8595 20.6096C53.2975 19.7808 55.0058 19.5571 56.6087 19.9879C58.2115 20.4186 59.5776 21.4685 60.4064 22.9064L66.6564 33.7502C69.9716 39.4938 70.8694 46.3191 69.1523 52.7246C67.4352 59.1302 63.2438 64.5912 57.5002 67.9064C51.7566 71.2216 44.9313 72.1194 38.5257 70.4023C32.1202 68.6852 26.6591 64.4938 23.3439 58.7502L10.2189 36.0002C9.80854 35.2882 9.54242 34.5023 9.43575 33.6874C9.32908 32.8726 9.38396 32.0447 9.59724 31.251C10.028 29.6482 11.0778 28.2821 12.5158 27.4533C13.9538 26.6245 15.6621 26.4009 17.2649 26.8316C18.8678 27.2624 20.2339 28.3122 21.0627 29.7502L27.9377 41.6564"
                stroke="url(#paint2_linear_72_2689)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M25.3438 75C21.0975 72.0712 17.5627 68.2267 15 63.75"
                stroke="url(#paint3_linear_72_2689)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M55 9.68756C57.8526 9.67983 60.6564 10.4275 63.1264 11.8546C65.5964 13.2817 67.6445 15.3374 69.0625 17.8126"
                stroke="url(#paint4_linear_72_2689)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_72_2689"
                  x1="39.1128"
                  y1="10.0405"
                  x2="39.1128"
                  y2="29.1563"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_72_2689"
                  x1="26.4253"
                  y1="11.9468"
                  x2="26.4253"
                  y2="33.2501"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_72_2689"
                  x1="39.694"
                  y1="19.7734"
                  x2="39.694"
                  y2="71.2551"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_72_2689"
                  x1="20.1719"
                  y1="63.75"
                  x2="20.1719"
                  y2="75"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_72_2689"
                  x1="62.0312"
                  y1="9.6875"
                  x2="62.0312"
                  y2="17.8126"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
              </defs>
            </svg>

            <h1 className="mt-6 text-2xl font-bold">Pendaftaran Berhasil.</h1>
            <p className="mt-4 text-sm text-text-secondary">
              Sebelum berpartisipasi sebagai media di PenaKita, Anda harus
              menentukan{" "}
              <span className="font-bold text-text-primary">
                rate publikasi brand content
              </span>{" "}
              media Anda untuk setiap publikasi. Media yang belum memiliki rate
              publikasi brand content belum bisa menerima atau mengambil rilis.
            </p>
            <p className="mt-4 text-sm text-text-secondary">
              Rate publikasi brand content bisa diganti kapanpun sesuai
              kebutuhan Anda.
            </p>

            <div className="mt-6 flex flex-col">
              <label className="text-sm font-bold">
                Rate Publikasi Brand Content (rupiah)
              </label>
              <Controller
                name="ratePublisherBrandContent"
                control={control}
                render={({ field: { onChange, onBlur, name, value } }) => (
                  <NumericFormat
                    placeholder="XXX.XXX"
                    className={`mt-2 px-4 py-2 text-2xl form-control ${
                      errors.ratePublisherBrandContent ? "is-invalid" : ""
                    }`}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    allowNegative={false}
                    onValueChange={(values) => onChange(values.floatValue)}
                    prefix="Rp "
                    name={name}
                    value={value}
                    onBlur={onBlur}
                  />
                )}
              />
              <div className="mt-1 invalid-feedback">
                {errors.ratePublisherBrandContent?.message}
              </div>
            </div>

            <div className="mt-8 flex justify-between space-x-4">
              <button
                className="w-full px-4 py-2 font-bold bg-white text-primary border border-primary rounded-xl hover:shadow-md"
                onClick={(e) => closeModal(e)}
              >
                Nanti Saja
              </button>
              <button className="w-full px-4 py-2 font-bold bg-gradient text-white rounded-xl hover:shadow-md">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Dashboard" />
          <DashboardSection>
            <OverviewCard />
            <NotificationCard />
            <AnnouncementCard />
            <WorkCard />
          </DashboardSection>
        </Main>
      </Layout>
    </>
  );
}

export default Dashboard;
