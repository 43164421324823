function MainSectionLoader() {
  return (
    <div
      className="flex flex-col p-4 h-60 bg-white rounded-2xl shadow-main mb-4"
    >
      <div className="flex-shrink-0 w-full animate-pulse overflow-hidden aspect-auto">
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between">
            <div className="w-1/4 p-2 bg-gray-200"></div>
            <div className="w-1/4 p-2 bg-gray-200"></div>
          </div>
          <div className="mt-4 w-1/2 p-2 bg-gray-200"></div>
          <div className="mt-4 w-full p-2 bg-gray-200"></div>
          <div className="mt-2 w-full p-2 bg-gray-200"></div>
          <div className="mt-2 w-full p-2 bg-gray-200"></div>
          <div className="mt-2 w-full p-2 bg-gray-200"></div>
          <div className="mt-2 w-full p-2 bg-gray-200"></div>
          <div className="mt-2 w-1/3 p-2 bg-gray-200"></div>
        </div>
      </div>
    </div>
  );
}

export default MainSectionLoader;
