import { DownloadIcon } from "assets";

function FileContentCard({ brief }) {
  return (
    <div className="p-4 flex flex-col space-y-4 bg-white rounded-2xl shadow-main">
      <h3 className="text-lg font-bold">Berkas-Berkas yang Diperlukan</h3>
      <hr />
      {/* <p className="font-bold">Gambar</p>
      <div className="flex space-x-4">
        <div className="text-white relative">
          {brief && (
            <img
              src={brief}
              alt="brief"
              className="w-[148px] max-h-[111px] rounded-2xl object-cover"
            />
          )}
          <DownloadIcon className="absolute z-10 bottom-2 right-2" />
        </div>
      </div>
      <button className="py-[10px] rounded-xl text-sm font-bold text-primary border border-primary">
        Unduh semua gambar (dalam zip)
      </button>
      <div className="py-4"> */}
      <p className="pb-2 text-sm font-bold">File Brief</p>
      <a
        className="text-sm"
        href={brief}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="px-4 py-[10px] rounded-xl border border-border hover:border-light-blue text-text-secondary flex items-center justify-between">
          <span className=" text-blue">{brief}</span>
          <DownloadIcon />
        </div>
      </a>
      {/* </div> */}
    </div>
  );
}

export default FileContentCard;
