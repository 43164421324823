import axios from "axios";
import authHeader from "./authHeader";

async function getProject(page, limit, orderBy, keyword) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/v1/take-release?page=${page}&limit=${limit}&orderBy=${orderBy}&keyword=${keyword}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getProjectDetail(id) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/v1/take-release/${id}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getOverview() {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/v1/overview`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getSummary() {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/v1/media/summary`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getWork(page, limit, status, type) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/v1/media?page=${page}&limit=${limit}&status=${status}&type=${type}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getWorkDetail(projectId) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/v1/media/${projectId}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function getWorkDeadline(status) {
  let result = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/projects/v1/closest-deadline/${status}`,
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function postEvidence(projectTransactionId, postLink) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/projects/v1/evidence`,
      {
        projectTransactionId,
        postLink,
      },
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function postPitch(id) {
  let result = {};

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/projects/v1/${id}/pitch`,
      {},
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

async function postApprove(id) {
  let result = {};

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/projects/v1/${id}/direct/approve`,
      {},
      {
        headers: authHeader(),
      }
    );
    result = response.data;
  } catch (error) {
    console.error(error.message)
    throw error;
  }

  return result;
}

export {
  getProject,
  getProjectDetail,
  getOverview,
  getSummary,
  getWork,
  getWorkDetail,
  getWorkDeadline,
  postEvidence,
  postPitch,
  postApprove,
};
