import { bgauth } from "assets";

function AuthLayout({ children }) {
  return (
    <div
      className="pt-8 px-4 w-full min-h-screen flex items-center justify-center bg-primary bg-right-top bg-auto md:bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${bgauth})` }}
    >
      {children}
    </div>
  );
}
export default AuthLayout;
