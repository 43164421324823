export default function HelpIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.54501 4.4998C4.66257 4.16563 4.89459 3.88385 5.19999 3.70436C5.50539 3.52488 5.86446 3.45927 6.2136 3.51915C6.56274 3.57904 6.87942 3.76056 7.10755 4.03156C7.33568 4.30256 7.46054 4.64556 7.46001 4.9998C7.46001 5.9998 5.96001 6.4998 5.96001 6.4998"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.5H6.005"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
