import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { UsersIcon } from "assets";

function ProfileNav({ className, profile }) {
  const [navIsOpen, setNavIsOpen] = useState(false);

  function testIsDesktop() {
    if (typeof window === "undefined") {
      return true;
    }
    return window.innerWidth >= 1024;
  }

  function useIsDesktopSize() {
    const [isDesktopSize, setIsDesktopSize] = useState(testIsDesktop);

    useEffect(() => {
      if (typeof window === "undefined") {
        return;
      }

      function autoResize() {
        setIsDesktopSize(testIsDesktop());
      }

      window.addEventListener("resize", autoResize);
      autoResize();
      return () => window.removeEventListener("resize", autoResize);
    }, []);

    return isDesktopSize;
  }

  const isDesktopSize = useIsDesktopSize();

  const menu = [
    {
      id: 1,
      title: "Edit Profil",
      desc: "Ubah logo, nama, email, dan data diri lainnya",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="10" stroke="#243242" strokeWidth="2" />
          <path
            d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 20C18 17.2386 15.3137 15 12 15C8.68629 15 6 17.2386 6 20"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      selected: true,
      to: "/profile",
    },
    {
      id: 2,
      title: "Ubah Alamat",
      desc: "Ubah alamat perusahaan",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      selected: false,
      to: "/address/edit",
    },
    {
      id: 3,
      title: "Ganti Password",
      desc: "Ganti password masuk",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 7.5L19 4M21 2L19 4L21 2ZM11.39 11.61C11.9063 12.1195 12.3168 12.726 12.5978 13.3948C12.8787 14.0635 13.0246 14.7813 13.027 15.5066C13.0295 16.232 12.8884 16.9507 12.6119 17.6213C12.3354 18.2919 11.9291 18.9012 11.4161 19.4141C10.9032 19.9271 10.2939 20.3334 9.6233 20.6099C8.95268 20.8864 8.234 21.0275 7.50863 21.025C6.78327 21.0226 6.06554 20.8767 5.39679 20.5958C4.72804 20.3148 4.12147 19.9043 3.612 19.388C2.61013 18.3507 2.05576 16.9614 2.06829 15.5193C2.08082 14.0772 2.65925 12.6977 3.679 11.678C4.69874 10.6583 6.07821 10.0798 7.52029 10.0673C8.96238 10.0548 10.3517 10.6091 11.389 11.611L11.39 11.61ZM11.39 11.61L15.5 7.5L11.39 11.61ZM15.5 7.5L18.5 10.5L22 7L19 4L15.5 7.5Z"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      selected: false,
      to: "/password/edit",
    },
    {
      id: 4,
      title: "Rate Publikasi Brand Content",
      desc: "Ubah rate untuk publikasi",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 20V10"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 20V4"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 20V14"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      selected: false,
      to: "/rate/edit",
    },
    {
      id: 5,
      title: "Riwayat Transaksi",
      desc: "Riwayat transaksi di Penakita",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 6H21"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 12H21"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 18H21"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 6H3.01"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 12H3.01"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 18H3.01"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      selected: false,
      to: "/history",
    },
    {
      id: 6,
      title: "Penarikan Dana",
      desc: "Tarik dana yang tersimpan di balance",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.75 6H2.25C1.83579 6 1.5 6.33579 1.5 6.75V17.25C1.5 17.6642 1.83579 18 2.25 18H21.75C22.1642 18 22.5 17.6642 22.5 17.25V6.75C22.5 6.33579 22.1642 6 21.75 6Z"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 6L22.5 11.25"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5 18L22.5 12.75"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 6L1.5 11.25"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 18L1.5 12.75"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      selected: false,
      to: "/withdraw",
    },
    {
      id: 7,
      title: "Review Klien",
      desc: "Penilaian-penilaian klien atas kinerja media",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
            stroke="#243242"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      selected: false,
      to: "/review",
    },
    {
      id: 8,
      title: "Manajemen Referral",
      desc: "Bagikan kode referral dan dapatkan komisinya",
      icon: <UsersIcon />,
      selected: false,
      to: "/referral",
    },
  ];

  return (
    <>
      {!isDesktopSize && (
        <>
          <span className="pb-2 flex justify-center font-bold text-xs text-text-secondary lg:hidden">
            Ubah profil, alamat, rate dan lainnya melalui menu berikut.
          </span>
          <div
            className={`w-auto py-2 mx-2 bg-white border border-blue font-bold flex items-center shadow-md cursor-pointer lg:hidden ${
              navIsOpen ? "rounded-t-xl border-b-0" : "rounded-xl"
            }`}
            onClick={() => setNavIsOpen(!navIsOpen)}
          >
            <span className="mx-auto">Menu Profil</span>
            <div className="mr-2">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </>
      )}
      {(navIsOpen || isDesktopSize) && (
        <nav
          className={`py-4 mx-2 lg:mx-0 rounded-b-xl lg:rounded-xl bg-white border lg:border-0 border-t-0 border-light-blue ${className}`}
        >
          <ul className="flex flex-col space-y-2">
            {menu
              .filter((x) => (profile?.isMediaPartner ? x : x.id !== 8))
              .map((item, i) => (
                <li
                  className="overflow-hidden overflow-ellipsis whitespace-nowrap"
                  key={i}
                >
                  <NavLink
                    to={item.to}
                    className={({ isActive }) =>
                      `flex px-3 py-1 hover:bg-border ${
                        isActive
                          ? "border-l-4 border-primary"
                          : "border-l-4 border-transparent"
                      }`
                    }
                  >
                    <span className="mr-4">{item.icon}</span>
                    <div className="flex flex-col space-y-1">
                      <span className="text-sm font-bold">{item.title}</span>
                      <span className="text-xs text-text-secondary">
                        {item.desc}
                      </span>
                    </div>
                  </NavLink>
                </li>
              ))}
          </ul>
        </nav>
      )}
    </>
  );
}
export default ProfileNav;
