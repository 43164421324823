import { useState, useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BurgerIcon, CloseIcon } from "assets";
import { Nav, HeaderContentRight, HeaderContentLeft } from "components";
import { ProfileContext } from "contexts/ProfileContext";
import { getNotifications } from "services/profileService";

function Header() {
  const { profilesData, isLoading } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [errorNotifications, setErrorNotifications] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  const goProfile = () => {
    navigate("/profile", { replace: true });
    setIsModalOpen(false);
  };

  const fetchNotifications = async () => {
    let result = {};
    try {
      const response = await getNotifications(1, 4, "Semua");
      if (response.data.length < 1) {
        setErrorNotifications(true);
      } else {
        setNotifications(response.data);
      }
    } catch (error) {
      setErrorNotifications(true);
      throw error;
    }
    return result;
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <header className="px-2 lg:px-4 bg-white shadow-main xl:rounded-2xl sticky top-0 z-40">
        <div className="w-full h-16 xl:h-[60px] mx-auto">
          <div className="flex items-center justify-between h-full xl:hidden">
            <button
              className="px-2 rounded-md hover:bg-background-orange xl:hidden"
              onClick={() => setNavIsOpen((x) => !x)}
            >
              {navIsOpen ? <CloseIcon /> : <BurgerIcon />}
            </button>
            <h6 className="font-bold">Dashboard</h6>
            <HeaderContentRight
              isVerified={profilesData?.isVerifiedPicEmail}
              isLoading={isLoading}
              isError={errorNotifications}
              notifData={notifications}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
          <HeaderContentLeft
            balance={profilesData?.totalBalance}
            rate={profilesData?.ratePublisherBrandContent}
            rateSme={profilesData?.rateSme}
            isLoading={isLoading}
            isError={errorNotifications}
            notifData={notifications}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
        {navIsOpen && (
          <div
            onClick={() => setNavIsOpen((x) => !x)}
            className="p-4 pt-20 xl:hidden fixed inset-0 z-30 bg-black bg-opacity-20"
          >
            <div
              onClick={(event) => event.stopPropagation()}
              className="h-16 px-4 bg-background rounded-t-2xl flex items-center justify-between"
            >
              <div>
                <NavLink
                  className="p-2 rounded-md hover:bg-border"
                  to="/dashboard"
                >
                  Dashboard
                </NavLink>
              </div>
              <button
                onClick={handleLogout}
                className="p-2 rounded-md text-error hover:bg-border"
              >
                Log Out
              </button>
            </div>
            <Nav
              onClick={(event) => event.stopPropagation()}
              className={`rounded-t-none`}
              image={profilesData?.companyProfilePicture}
            />
          </div>
        )}
      </header>

      {/* Modal */}
      <div
        className={`${
          isModalOpen ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <div className="p-4 lg:p-8 w-full flex flex-col">
            <svg
              className="w-10 h-10 lg:w-20 lg:h-20"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.3332 36.9332V39.9999C73.3291 47.1879 71.0015 54.1821 66.6976 59.9393C62.3937 65.6965 56.3441 69.9081 49.451 71.9462C42.5579 73.9843 35.1907 73.7395 28.4481 71.2485C21.7054 68.7574 15.9487 64.1535 12.0364 58.1234C8.12406 52.0933 6.26581 44.9601 6.73876 37.7876C7.21171 30.6151 9.99053 23.7876 14.6608 18.3235C19.331 12.8593 25.6425 9.05115 32.6538 7.46703C39.6651 5.88291 47.0007 6.60766 53.5665 9.53321"
                stroke="url(#paint0_linear_70_2489)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M73.3333 13.333L40 46.6997L30 36.6997"
                stroke="url(#paint1_linear_70_2489)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_70_2489"
                  x1="39.9998"
                  y1="6.64746"
                  x2="39.9998"
                  y2="73.3142"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_70_2489"
                  x1="51.6667"
                  y1="13.333"
                  x2="51.6667"
                  y2="46.6997"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
              </defs>
            </svg>

            <h2 className="mt-4 lg:mt-8 text-lg lg:text-2xl font-bold">
              Status Verifikasi
            </h2>
            <div className="mt-4 p-4 bg-background border border-border rounded-2xl">
              <p className="text-sm text-text-secondary">
                Status Verifikasi Perusahaan Anda
              </p>
              <h2 className="mt-2 text-base lg:text-2xl font-bold text-primary">
                {profilesData?.isVerifiedPicEmail !== 0 ? "Sudah" : "Belum"}{" "}
                Terverifikasi
              </h2>
            </div>
            <p className="mt-4 text-sm text-text-secondary">
              Sebelum Anda dapat melakukan transaksi di Penakita, akun Anda
              harus diverifikasi terlebih dahulu. Tim Penakita akan menghubung
              PIC perusahaan Anda untuk verifikasi.
            </p>
            <div className="flex space-x-4">
              <button
                className="mt-8 w-1/2 px-2 lg:px-4 py-2 text-sm lg:text-base font-bold text-primary border border-primary rounded-xl hover:shadow-md"
                onClick={() => goProfile()}
              >
                Ganti Kontak PIC
              </button>
              <button
                className="mt-8 w-1/2 px-2 lg:px-4 py-2 text-sm lg:text-base font-bold bg-gradient text-white rounded-xl hover:shadow-md"
                onClick={() => setIsModalOpen(false)}
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
