import { useState } from "react";
import { postApprove } from "services/projectService";
import { Alert } from "components";
import { currency } from "utils";

function ApproveCard({ rate, title, id, reloadPage }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    postApprove(id)
      .then((res) => {
        if (res) {
          Alert.fire({
            icon: "success",
            title: "Sukses",
            text: `Berhasil menerima pemuatan rilis ${title}`,
            showCancelButton: false,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              setIsModalOpen(false);
              reloadPage();
            }
          });
        }
      })
      .catch((res) => {
        if (res.response.status !== 401) {
          Alert.fire({
            icon: "error",
            title: "Terjadi kesalahan",
            text: `Pesan error: ${res.response.data.message}`,
          });
        }
      });
  };

  const closeModal = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="mb-4 p-4 flex flex-col space-y-4 bg-white rounded-2xl shadow-main">
        <h3 className="text-lg font-bold">Rate Publikasi Brand Content</h3>
        <hr />
        <h1 className="text-[32px] font-bold text-primary">{currency(rate)}</h1>
        <button
          className="py-[10px] bg-gradient rounded-xl text-sm font-bold text-white"
          onClick={() => setIsModalOpen(true)}
        >
          Terima Pemuatan Rilis
        </button>
      </div>

      {/* Modal */}
      <div
        className={`${
          isModalOpen ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <form onSubmit={handleSubmit} className="p-8 w-full flex flex-col">
            <svg
              className="w-10 h-10 lg:w-20 lg:h-20"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M73.3332 36.9332V39.9999C73.3291 47.1879 71.0015 54.1821 66.6976 59.9393C62.3937 65.6965 56.3441 69.9081 49.451 71.9462C42.5579 73.9843 35.1907 73.7395 28.4481 71.2485C21.7054 68.7574 15.9487 64.1535 12.0364 58.1234C8.12406 52.0933 6.26581 44.9601 6.73876 37.7876C7.21171 30.6151 9.99053 23.7876 14.6608 18.3235C19.331 12.8593 25.6425 9.05115 32.6538 7.46703C39.6651 5.88291 47.0007 6.60766 53.5665 9.53321"
                stroke="url(#paint0_linear_70_2489)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M73.3333 13.333L40 46.6997L30 36.6997"
                stroke="url(#paint1_linear_70_2489)"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_70_2489"
                  x1="39.9998"
                  y1="6.64746"
                  x2="39.9998"
                  y2="73.3142"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_70_2489"
                  x1="51.6667"
                  y1="13.333"
                  x2="51.6667"
                  y2="46.6997"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FC891B" />
                  <stop offset="1" stopColor="#FFA729" />
                </linearGradient>
              </defs>
            </svg>

            <h1 className="mt-4 text-2xl font-bold">
              Apakah Anda ingin terima pemuatan rilis?
            </h1>
            <p className="mt-2 text-sm text-text-secondary leading-relaxed">
              Anda akan menerima pekerjaan{" "}
              <span className="font-bold">{title}</span>
            </p>

            <div className="mt-4 flex justify-between space-x-4">
              <button
                className="w-full px-4 py-2 font-bold bg-white text-primary border border-primary rounded-xl hover:shadow-md"
                onClick={(e) => closeModal(e)}
              >
                Batal
              </button>
              <button className="w-full px-4 py-2 font-bold bg-gradient text-white rounded-xl hover:shadow-md">
                Terima
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ApproveCard;
