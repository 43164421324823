import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

function useReactiveSearchParams(params) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [reactiveSearchParamsState, setReactiveSearchParamsState] = useState(
    Object.fromEntries(searchParams)
  );

  useEffect(() => {
    setReactiveSearchParamsState(Object.fromEntries(searchParams));
  }, [searchParams]);

  const reactiveSearchParams = reactiveSearchParamsState;
  const setReactiveSearchParams = (params = {}) =>
    navigate({
      pathname: window.location.pathname,
      search: `?${createSearchParams(params)}`,
    });

  return [reactiveSearchParams, setReactiveSearchParams];
}

export default useReactiveSearchParams;
