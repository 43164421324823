import { useState, useEffect } from "react";
import { Alert } from "components";
import { postPitch } from "services/projectService";
import { currency } from "utils";

function RateCard({ rate, id, reloadPage, isJoin, isExpired, canJoin }) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const submitPitch = () => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengikuti proses pitching.",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Konfirmasi",
    }).then((result) => {
      if (result.isConfirmed) {
        postPitch(id)
          .then((res) => {
            if (res) {
              reloadPage();
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Berhasil mengikuti proses pitching.",
              });
            }
          })
          .catch((res) => {
            if (res.response.status !== 401) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${res.response.data.message}`,
              });
            }
          });
      }
    });
  };

  return (
    <div className="mb-4 p-4 flex flex-col space-y-4 bg-white rounded-2xl shadow-main">
      <h3 className="text-lg font-bold">Rate Publikasi Brand Content</h3>
      <hr />
      <h1 className="text-[32px] font-bold text-primary">{currency(rate)}</h1>
      {!isLoading && (
        <button
          className={`w-full lg:w-[412px] py-[10px] rounded-xl text-sm font-bold text-white ${
            isJoin || isExpired || !canJoin
              ? "bg-text-secondary cursor-not-allowed"
              : "bg-gradient"
          }`}
          onClick={submitPitch}
          disabled={isJoin || isExpired || !canJoin}
        >
          {isExpired && "Pekerjaan telah berakhir"}
          {!isExpired &&
            isJoin &&
            "Anda sudah melakukan pitch pada pekerjaan ini"}
          {!isExpired &&
            !isJoin &&
            !canJoin &&
            "Pekerjaan tidak sesuai dengan tier media anda"}
          {!isJoin && !isExpired && canJoin && "Pitch"}
        </button>
      )}
      {isLoading && (
        <div className="w-full flex flex-col justify-center animate-pulse overflow-hidden">
          <div className="w-full p-5 bg-gray-200"></div>
        </div>
      )}
    </div>
  );
}

export default RateCard;
