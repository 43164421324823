import { useState, useEffect, useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import {
  Layout,
  Sidebar,
  Nav,
  Header,
  Main,
  BreadCrumb,
  ErrorCard,
  MainSectionLoader,
  RightSectionLoader,
} from "components";
import Title from "components/Title";
import WorkDetailSection from "./components/WorkDetailSection";
import ContentSection from "./components/ContentSection";
import MainContentCard from "./components/MainContentCard";
import FileContentCard from "./components/FileContentCard";
import EvidenceCard from "./components/EvidenceCard";
import DeadlineCard from "pages/JobDetail/components/DeadlineCard";
import RightContentSection from "./components/RightContentSection";
import ApproveCard from "./components/ApproveCard";
import { getWorkDetail } from "services/projectService";
import { useParams } from "react-router-dom";
import { useCountdown } from "utils/useCountdown";

function WorkDetail() {
  const { profilesData } = useContext(ProfileContext);
  const { id } = useParams();
  const [work, setWork] = useState();
  const [reload, setReload] = useState(1);
  const [dateTime, setDateTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const fetchWork = async () => {
      setIsLoading(true);
      let result = {};
      try {
        const response = await getWorkDetail(id);
        setWork(response.data);
        setDateTime(response.data.deadLine + " 00:00");
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        throw error;
      }
      return result;
    };
    fetchWork();
  }, [id, reload]);

  const reloadPage = () => {
    setReload(reload + 1);
  };

  const dateFormat = new Date(dateTime);
  const dateTimeToMiliseconds = dateFormat.getTime();
  const dateTimeConverted = dateTimeToMiliseconds;

  useEffect(() => {
    if (work !== undefined && work?.status === "Telah Selesai") {
      setIsDone(true);
    } else {
      setIsDone(false);
    }
  }, [work]);

  const [days, hours, minutes, seconds] = useCountdown(dateTimeConverted);
  const [isExpired, setIsExpired] = useState(false);
  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }
  }, [dateTimeConverted, days, hours, minutes, seconds]);

  const [isWaiting, setIsWaiting] = useState(false);
  const [isReject, setIsReject] = useState(false);
  useEffect(() => {
    switch (work !== undefined && work?.status) {
      case "Menunggu":
        setIsWaiting(true);
        break;
      case "Ditolak":
        setIsReject(true);
        break;
      default:
        setIsWaiting(false);
        setIsWaiting(false);
        break;
    }
  }, [work]);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Detail Pekerjaan" />
          <BreadCrumb title={work?.name} root="Pekerjaan" />
          <WorkDetailSection>
            <ContentSection>
              {isLoading && [0, 0].map(() => <MainSectionLoader />)}
              {!isError && !isLoading && (
                <>
                  <MainContentCard
                    image={work?.image}
                    username={work?.createdBy?.name}
                    photo={work?.createdBy?.photo}
                    status={work?.status}
                    title={work?.name}
                    description={work?.description}
                  />
                  <FileContentCard brief={work?.fileBrief} />
                </>
              )}
              {isError && (
                <ErrorCard
                  text="Gagal Memperoleh Informasi"
                  className="h-56 flex items-center justify-center text-error"
                />
              )}
            </ContentSection>
            <RightContentSection>
              {isLoading && [0, 0].map(() => <RightSectionLoader />)}
              {!isError && !isLoading && (
                <>
                  {work?.status === "Permintaan Pemuatan Rilis" ? (
                    <ApproveCard
                      rate={work?.ratePublisherBrandContent}
                      title={work?.name}
                      id={work?.projectTransactionId}
                      reloadPage={reloadPage}
                    />
                  ) : (
                    <EvidenceCard
                      evidence={work?.evidence.postLink}
                      title={work?.name}
                      params={id}
                      reloadPage={reloadPage}
                      isDone={isDone}
                      isExpired={isExpired}
                      isWaiting={isWaiting}
                      isReject={isReject}
                      status={work?.status}
                    />
                  )}
                  <DeadlineCard
                    deadline={work?.deadLine}
                    isDone={isDone}
                    isExpired={isExpired}
                    days={days}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                  />
                </>
              )}
              {isError && (
                <ErrorCard
                  text="Gagal Memperoleh Informasi"
                  className="h-56 flex items-center justify-center text-error"
                />
              )}
            </RightContentSection>
          </WorkDetailSection>
        </Main>
      </Layout>
    </>
  );
}

export default WorkDetail;
