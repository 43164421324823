import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { updateBank, withdraw, getBank } from "services/profileService";
import { Alert } from "components";
import { NumericFormat } from "react-number-format";
import { currency } from "utils";

function WithdrawCard({ profile, isLoading, refresh, setRefresh }) {
  const validationSchema = Yup.object().shape({
    bankId: Yup.string().required("Bank tidak boleh kosong").nullable(),
    bankAccountNumber: Yup.string()
      .required("Nomor rekening tidak boleh kosong")
      .nullable(),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      bankId: "",
      bankAccountNumber: "",
    },
  });

  useEffect(() => {
    let defaults = {
      bankId: profile?.bankId,
      bankAccountNumber: profile?.bankAccountNumber,
    };
    reset(defaults);
  }, [profile, reset]);

  const [bankList, setBankList] = useState([]);
  const fetchBank = async () => {
    let result = {};
    const response = await getBank();
    setBankList(response);
    return result;
  };

  useEffect(() => {
    fetchBank();
  }, []);

  const [modalBank, setModalBank] = useState(false);
  const [modalBalance, setModalBalance] = useState(false);
  const openModalBank = () => {
    setModalBank(true);
  };
  const closeModalBank = (e) => {
    e.preventDefault();
    reset();
    setModalBank(false);
  };
  const openModalBalance = () => {
    setModalBalance(true);
  };
  const closeModalBalance = (e) => {
    e.preventDefault();
    reset();
    setModalBalance(false);
  };

  const [selectedBank, setSelectedBank] = useState({});
  const submitBank = (e) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah bank / nomor rekening!",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Konfirmasi",
    }).then((result) => {
      if (result.isConfirmed) {
        updateBank(e.bankAccountNumber, selectedBank.id, selectedBank.name)
          .then((res) => {
            if (res) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: "Bank / nomor rekening berhasil diubah.",
              });
              setModalBank(false);
            }
          })
          .catch((res) => {
            if (res.response.status !== 401) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${res.response.data.message}`,
              });
            }
          });
      }
    });
  };

  const submitBalance = (e) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan melakukan penarikan dana.",
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Konfirmasi",
    }).then((result) => {
      if (result.isConfirmed) {
        withdraw(e.totalAmount)
          .then((res) => {
            if (res) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Penarikan Dana",
                text: `Dana sebesar ${currency(
                  e.totalAmount
                )} akan segera kami transfer ke rekening bank Anda.`,
              });
              setModalBalance(false);
            }
          })
          .catch((res) => {
            if (res.response.status !== 401) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${res.response.data.message}`,
              });
            }
          });
      }
    });
  };

  return (
    <>
      <div className="mt-4 lg:mt-0 w-full lg:max-w-[787px] h-max p-4 flex flex-col space-y-4 bg-white lg:rounded-2xl shadow-main lg:ml-4">
        <div className="flex items-center space-x-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.75 6H2.25C1.83579 6 1.5 6.33579 1.5 6.75V17.25C1.5 17.6642 1.83579 18 2.25 18H21.75C22.1642 18 22.5 17.6642 22.5 17.25V6.75C22.5 6.33579 22.1642 6 21.75 6Z"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 6L22.5 11.25"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 18L22.5 12.75"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 6L1.5 11.25"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 18L1.5 12.75"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="text-lg font-bold">Penarikan dana</h3>
        </div>
        <hr />
        <div className="my-8 flex flex-col space-y-4">
          <div className="pb-4 flex flex-col lg:flex-row justify-between lg:items-center">
            <div className="flex flex-col space-y-2 lg:space-y-4">
              <span className="text-sm">Rekening</span>
              <span className="text-2xl font-bold">
                {isLoading && !profile?.bankAccountNumber && (
                  <div className="w-60 animate-pulse overflow-hidden">
                    <div className="w-full p-4 bg-gray-200 rounded"></div>
                  </div>
                )}
                {!isLoading &&
                  profile?.bankAccountNumber &&
                  profile.bankAccountNumber}
                {!isLoading &&
                  !profile?.bankAccountNumber &&
                  "Belum ada rekening terdaftar"}
              </span>
              <span className="text-sm text-text-secondary">
                {profile?.bank?.name}
              </span>
            </div>
            <button
              className="mt-4 lg:mt-0 w-full md:w-44 h-10 bg-gradient text-white text-sm font-bold rounded-xl"
              onClick={openModalBank}
            >
              Ganti Nomor Rekening
            </button>
          </div>

          <hr />
          <div className="py-4 flex flex-col lg:flex-row justify-between lg:items-center">
            <div className="flex flex-col space-y-2 lg:space-y-4">
              <span className="text-sm">Jumlah Saldo</span>
              <span className="text-2xl font-bold">
                {isLoading && !profile?.totalBalance && (
                  <div className="w-60 animate-pulse overflow-hidden">
                    <div className="w-full p-4 bg-gray-200 rounded"></div>
                  </div>
                )}
                {!isLoading && currency(profile?.totalBalance)}
              </span>
            </div>
            <button
              className={`${
                profile?.totalBalance > 0 && profile?.bankAccountNumber
                  ? "bg-gradient"
                  : "bg-text-secondary cursor-not-allowed"
              } mt-4 lg:mt-0 w-full md:w-44 h-10 text-white text-sm font-bold rounded-xl`}
              onClick={profile?.totalBalance > 0 ? openModalBalance : undefined}
              disabled={!profile?.bankAccountNumber}
            >
              Tarik Dana
            </button>
          </div>
        </div>
      </div>

      {/* Modal Bank */}
      <div
        className={`${
          modalBank ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <form
            onSubmit={handleSubmit(submitBank)}
            className="p-8 w-full flex flex-col"
          >
            <h1 className="text-2xl font-bold">Nomor Rekening</h1>
            <p className="pt-4 text-sm text-text-secondary">
              Silahkan masukkan nomor rekening Anda yang bisa digunakan untuk
              keperluan penarikan dana dari saldo PenaKita.
            </p>
            <div className="mt-4 grid grid-cols-1 gap-4">
              <div className="flex flex-col">
                <label className="text-sm font-bold">Bank</label>
                <select
                  name="bankId"
                  {...register("bankId", {
                    onChange: (e) => {
                      setSelectedBank(bankList[e.target.value - 1]);
                    },
                  })}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.bankId ? "is-invalid" : ""
                  }`}
                >
                  <option value="">Pilih Bank</option>
                  {bankList &&
                    bankList.map((bank) => (
                      <option value={bank.id} key={bank.id}>
                        {bank.name}
                      </option>
                    ))}
                </select>
                <div className="mt-1 invalid-feedback">
                  {errors.bankId?.message}
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-bold">Nomor Rekening</label>
                <input
                  type="number"
                  min="0"
                  name="bankAccountNumber"
                  {...register("bankAccountNumber")}
                  className={`mt-2 px-4 py-2 text-sm form-control ${
                    errors.bankAccountNumber ? "is-invalid" : ""
                  }`}
                  placeholder="Masukkan nomor rekening"
                />
                <div className="mt-1 invalid-feedback">
                  {errors.bankAccountNumber?.message}
                </div>
              </div>
            </div>

            <div className="mt-8 flex justify-between space-x-4">
              <button
                className="w-full px-4 py-2 font-bold bg-white text-primary border border-primary rounded-xl hover:shadow-lg"
                onClick={(e) => closeModalBank(e)}
              >
                Batal
              </button>
              <button className="w-full px-4 py-2 font-bold bg-gradient text-white rounded-xl hover:shadow-lg">
                Ganti
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Modal Balance */}
      <div
        className={`${
          modalBalance ? "modal-overlay show-modal" : "modal-overlay"
        }`}
      >
        <div className="modal-container">
          <form
            onSubmit={handleSubmit(submitBalance)}
            className="p-8 w-full flex flex-col"
          >
            <h1 className="text-2xl font-bold">Penarikan Dana</h1>
            <p className="pt-4 text-sm text-text-secondary">
              Silahkan masukkan jumlah dana yang ingin Anda tarik dari saldo di
              PenaKita ke rekening bank.
            </p>
            <div className="mt-4 grid grid-cols-1 gap-4">
              <div className="flex flex-col">
                <label className="text-sm font-bold">Jumlah yang ditarik</label>
                <Controller
                  name="totalAmount"
                  control={control}
                  render={({ field: { onChange, onBlur, name, value } }) => (
                    <NumericFormat
                      placeholder="XXX.XXX"
                      className={`mt-2 px-4 py-2 text-2xl form-control ${
                        errors.totalAmount ? "is-invalid" : ""
                      }`}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      allowNegative={false}
                      onValueChange={(values) => onChange(values.floatValue)}
                      prefix="Rp "
                      name={name}
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
                <div className="mt-1 invalid-feedback">
                  {errors.totalAmount?.message}
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-sm font-bold">Saldo di Penakita</label>
                <p className="text-lg font-bold">
                  {currency(profile?.totalBalance)}
                </p>
              </div>
            </div>

            <div className="mt-8 flex justify-between space-x-4">
              <button
                className="w-full px-4 py-2 font-bold bg-white text-primary border border-primary rounded-xl ease-in-out duration-200 hover:shadow-lg"
                onClick={(e) => closeModalBalance(e)}
              >
                Tutup
              </button>
              <button className="w-full px-4 py-2 font-bold bg-gradient text-white rounded-xl ease-in-out duration-200 hover:shadow-lg">
                Tarik Dana
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default WithdrawCard;
