import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  updateRate,
  updateRateSme,
  getHistoryRate,
} from "services/profileService";
import { Alert } from "components";
import { NumericFormat } from "react-number-format";
import DataTable from "react-data-table-component";
import { format, parseISO } from "date-fns";
import { currency } from "utils";

function RateCard({ profile, refresh, setRefresh }) {
  const [history, setHistory] = useState([]);
  const [limitPage, setLimitPage] = useState(10);
  const [totalRows, setTotalRows] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rateType, setRateType] = useState("");

  const fetchHistory = async (page) => {
    setLoading(true);
    try {
      const response = await getHistoryRate(page, limitPage);
      setHistory(response.data);
      setTotalRows(response.meta.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchHistory(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await getHistoryRate(page, newPerPage);
      setHistory(response.data);
      setLimitPage(newPerPage);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory(1);
  }, []);

  // table style
  const customStyles = {
    headCells: {
      style: {
        background: "#E8E8E8",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
  };
  // custom columns
  const Rate = (row) => (
    <div className="font-bold">
      {currency(row.ratePublisherBrandContent)}
    </div>
  );

  const columns = [
    {
      name: "Tanggal Diubah",
      selector: (row) => row.createdAt,
      wrap: true,
      cell: (row) => format(parseISO(row.createdAt), "d MMM y HH:mm"),
    },
    {
      name: "Diubah oleh",
      selector: (row) => row.createdBy,
      wrap: true,
    },
    {
      name: "Id Media",
      selector: (row) => row.userMediaId,
      wrap: true,
    },
    {
      name: "Tipe",
      selector: (row) => row.type,
      wrap: true,
    },
    {
      name: "Nominal",
      selector: (row) => row.ratePublisherBrandContent,
      wrap: true,
      cell: (row) => <Rate {...row} />,
    },
  ];

  function TableComponent() {
    return (
      <DataTable
        columns={columns}
        data={history}
        noDataComponent="Belum ada data"
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationRowsPerPageOptions={[10, 15, 20]}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        striped
      />
    );
  }

  const validationSchema = Yup.object().shape({
    ratePublisherBrandContent: Yup.string().required(
      "Rate korporasi tidak boleh kosong"
    ),
    rateSme: Yup.string().required("Rate umkm tidak boleh kosong"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    defaultValues: {
      ratePublisherBrandContent: "",
      rateSme: "",
    },
  });

  useEffect(() => {
    let defaults = {
      ratePublisherBrandContent: profile?.ratePublisherBrandContent,
      rateSme: profile?.rateSme,
    };
    reset(defaults);
  }, [profile, reset]);

  const handleRateUpdate = (promise, message) => {
    Alert.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: `Anda akan mengubah Rate ${message}!`,
      showCancelButton: true,
      cancelButtonText: "Batalkan",
      confirmButtonText: "Konfirmasi",
    }).then((result) => {
      if (result.isConfirmed) {
        promise
          .then((res) => {
            if (res) {
              setRefresh(refresh + 1);
              Alert.fire({
                icon: "success",
                title: "Sukses",
                text: `Rate ${message} berhasil diubah.`,
              });
            }
          })
          .catch((error) => {
            if (error.response && error.response.status !== 401) {
              Alert.fire({
                icon: "error",
                title: "Terjadi kesalahan",
                text: `Pesan error: ${
                  error.response.data.message.toLowerCase().includes("rate") &&
                  `Rate ${message} hanya bisa diganti 1 kali dalam 30 hari.`
                }`,
              });
            }
          });
      }
    });
  };

  const onSubmit = (e) => {
    rateType === "corporate"
      ? handleRateUpdate(updateRate(e.ratePublisherBrandContent), "Korporasi")
      : handleRateUpdate(updateRateSme(e.rateSme), "UMKM");
  };

  return (
    <>
      <div className="mt-4 lg:mt-0 w-full lg:max-w-[787px] h-max p-4 flex flex-col space-y-4 bg-white lg:rounded-2xl shadow-main">
        <div className="flex items-center space-x-4">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 20V10"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 20V4"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 20V14"
              stroke="#243242"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="text-lg font-bold">Rate Publikasi Brand Content</h3>
        </div>
        <hr />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-4">
            <p className="text-sm text-text-secondary">
              Penggantian Rate Publikasi Brand Content tidak berpengaruh
              terhadap rilis yang sudah di-pitch atau yang sudah ditawarkan ke
              media Anda.
            </p>
            <p className="text-sm text-text-secondary">
              Catatan: Rate Publikasi Brand Content hanya bisa diganti maksimal
              1 kali dalam 30 hari.
            </p>
          </div>

          <div className="mt-4 flex space-x-4">
            <div className="w-1/2" onClick={() => setRateType("corporate")}>
              <div
                className={`${
                  rateType === "corporate" && "border-blue"
                } p-4 border cursor-pointer rounded-xl`}
              >
                <div className="w-full">
                  <div className="flex items-center justify-center space-x-2 w-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`${
                        rateType === "corporate" && "text-blue"
                      } w-4 h-4 text-text-secondary`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <p className="text-sm font-bold">Rate Korporasi</p>
                  </div>
                  {rateType === "corporate" ? (
                    <div className="pt-6 flex flex-col">
                      <label className="text-sm font-bold">
                        Nominal Rate Korporasi (Rupiah)
                      </label>
                      <Controller
                        name="ratePublisherBrandContent"
                        control={control}
                        render={({
                          field: { onChange, onBlur, name, value },
                        }) => (
                          <NumericFormat
                            placeholder="XXX.XXX"
                            className={`mt-2 px-4 py-[15px] text-sm form-control ${
                              errors.ratePublisherBrandContent
                                ? "is-invalid"
                                : ""
                            }`}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            allowNegative={false}
                            onValueChange={(values) =>
                              onChange(values.floatValue)
                            }
                            prefix="Rp "
                            name={name}
                            value={value}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <div className="mt-1 invalid-feedback">
                        {errors.ratePublisherBrandContent?.message}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="w-1/2" onClick={() => setRateType("umkm")}>
              <div
                className={`${
                  rateType === "umkm" && "border-blue"
                } p-4 border cursor-pointer rounded-xl`}
              >
                <div className="w-full">
                  <div className="flex items-center justify-center space-x-2 w-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`${
                        rateType === "umkm" && "text-blue"
                      } w-4 h-4 text-text-secondary`}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <p className="text-sm font-bold">Rate UMKM</p>
                  </div>

                  {rateType === "umkm" ? (
                    <div className="pt-6 flex flex-col">
                      <label className="text-sm font-bold">
                        Nominal Rate UMKM (Rupiah)
                      </label>
                      <Controller
                        name="rateSme"
                        control={control}
                        render={({
                          field: { onChange, onBlur, name, value },
                        }) => (
                          <NumericFormat
                            placeholder="XXX.XXX"
                            className={`mt-2 px-4 py-[15px] text-sm form-control ${
                              errors.rateSme ? "is-invalid" : ""
                            }`}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            allowNegative={false}
                            onValueChange={(values) =>
                              onChange(values.floatValue)
                            }
                            prefix="Rp "
                            name={name}
                            value={value}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <div className="mt-1 invalid-feedback">
                        {errors.rateSme?.message}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              className="mt-4 w-full lg:w-auto lg:px-16 py-[10px] bg-gradient text-white text-sm font-bold rounded-xl"
              type="submit"
            >
              Simpan
            </button>
          </div>
        </form>

        <div className="pt-2">
          <h4 className="pb-4 text-sm font-bold">
            Riwayat Pergantian Nominal Rate
          </h4>
          <div className="rounded">{TableComponent()}</div>
        </div>
      </div>
    </>
  );
}

export default RateCard;
