import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ProfileContext } from "contexts/ProfileContext";
import {
  Layout,
  Sidebar,
  Nav,
  Header,
  Main,
  BreadCrumb,
  MainSectionLoader,
  RightSectionLoader,
  ErrorCard,
} from "components";
import Title from "components/Title";
import JobDetailSection from "./components/JobDetailSection";
import MainContentCard from "./components/MainContentCard";
import RightContentSection from "./components/RightContentSection";
import RateCard from "./components/RateCard";
import DeadlineCard from "./components/DeadlineCard";
import OtherCard from "./components/OtherCard";
import { useCountdown } from "utils/useCountdown";
import { getProject, getProjectDetail } from "services/projectService";

function JobDetail() {
  const { profilesData } = useContext(ProfileContext);
  const { id } = useParams();
  const [project, setProject] = useState({});
  const [reload, setReload] = useState(1);
  const [dateTime, setDateTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  const [canJoin, setCanJoin] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      setIsLoading(true);
      let result = {};
      try {
        const response = await getProjectDetail(id);
        if (response.success === true) {
          setProject(response.data);
          setDateTime(response.data.end_period);
          setIsJoin(response.data.is_join);
          setCanJoin(response.data.can_join);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        throw error;
      }
      return result;
    };
    fetchProject();
  }, [id, reload]);

  const reloadPage = () => {
    setReload(reload + 1);
  };

  const dateFormat = new Date(dateTime);
  const dateTimeToMiliseconds = dateFormat.getTime();
  const dateTimeConverted = dateTimeToMiliseconds;

  const [days, hours, minutes, seconds] = useCountdown(dateTimeConverted);
  const [isExpired, setIsExpired] = useState(false);
  useEffect(() => {
    if (days + hours + minutes + seconds <= 0) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }
  }, [dateTimeConverted, days, hours, minutes, seconds]);

  const [projects, setProjects] = useState([]);
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await getProject(1, 10, "Terbaru", "");
        setProjects(response.data);
      } catch (error) {
        setIsError(true);
      }
    };
    fetchProject();
  }, []);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Detail Pekerjaan" />
          <BreadCrumb title={project?.name} root="Ambil Rilis" />
          <JobDetailSection>
            <div className="w-full h-full flex flex-col">
              {isLoading && [0, 0].map((x, i) => <MainSectionLoader key={i} />)}
              {!isError && !isLoading && (
                <MainContentCard
                  image={project?.image}
                  username={project?.user_name}
                  photo={project?.user_photo}
                  created={project?.created_at}
                  title={project?.name}
                  description={project?.description}
                  objective={project?.objective}
                />
              )}
              {isError && (
                <ErrorCard
                  text="Gagal Memperoleh Informasi"
                  className="h-56 flex items-center justify-center text-error"
                />
              )}
            </div>
            <RightContentSection>
              {isLoading &&
                [0, 0].map((x, i) => <RightSectionLoader key={i} />)}
              {!isError && !isLoading && (
                <>
                  <RateCard
                    rate={project?.budget}
                    id={project?.project_id}
                    reloadPage={reloadPage}
                    isJoin={isJoin}
                    isExpired={isExpired}
                    canJoin={canJoin}
                  />
                  <DeadlineCard
                    deadline={project?.end_period}
                    isExpired={isExpired}
                    days={days}
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                  />
                  <OtherCard
                    projects={projects}
                    projectId={project?.project_id}
                  />
                </>
              )}
              {isError && (
                <ErrorCard
                  text="Gagal Memperoleh Informasi"
                  className="h-56 flex items-center justify-center text-error"
                />
              )}
            </RightContentSection>
          </JobDetailSection>
        </Main>
      </Layout>
    </>
  );
}

export default JobDetail;
