import { useContext } from "react"
import { ProfileContext } from "contexts/ProfileContext";
import { Layout, Sidebar, Nav, Header, Main, ProfileMenu, ProfileNav } from "components";
import Title from "components/Title";
import HistorySection from "./components/HistorySection";
import HistoryCard from "./components/HistoryCard";

function History() {
  const { profilesData } = useContext(ProfileContext);

  return (
    <>
      <Layout>
        <Sidebar>
          <Nav image={profilesData?.companyProfilePicture} />
        </Sidebar>
        <Main>
          <Header />
          <Title title="Profile" />
          <HistorySection>
            <ProfileMenu>
              <ProfileNav profile={profilesData} />
            </ProfileMenu>
            <HistoryCard />
          </HistorySection>
        </Main>
      </Layout>
    </>
  );
}

export default History;
