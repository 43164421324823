import { useState, useEffect } from "react";
import { getAnnouncement } from "services/profileService";
import { format, parseISO } from "date-fns";
import { ChatIcon } from "assets";

function AnnouncementCard() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const page = 1;
  const limit = 20;
  const type = "media";

  const fetchAnnouncement = async () => {
    setIsLoading(true);
    let result = {};
    try {
      const response = await getAnnouncement(page, limit, type);
      if (response.data.length > 0) {
        setData(response.data);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      throw error;
    }
    return result;
  };

  useEffect(() => {
    fetchAnnouncement();
  }, []);

  return (
    <>
      <div className="p-4 flex flex-col space-y-4 bg-white rounded-2xl shadow-main h-fit">
        <h3 className="text-lg font-bold">E-Announcement / Campaign</h3>
        <div className="mt-4 flex flex-col space-y-4">
          {isLoading && (
            <div className="w-full animate-pulse overflow-hidden">
              <div className="flex flex-col">
                <div className="mx-auto w-1/2 p-2 bg-gray-200 rounded"></div>
                <div className="mt-2 w-full p-2 bg-gray-200 rounded"></div>
                <div className="mt-2 w-full p-2 bg-gray-200 rounded"></div>
                <div className="mt-2 w-1/2 p-2 bg-gray-200 rounded"></div>
                <div className="mt-6 flex justify-between">
                  <div className="w-1/4 p-2 bg-gray-200 rounded"></div>
                  <div className="w-1/4 p-2 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          )}

          {!isError &&
            !isLoading &&
            data &&
            data.map((x) => (
              <div
                className="p-2 md:p-3 w-full min-h-full rounded-lg flex flex-col space-y-2 bg-background shadow"
                key={x.id}
              >
                <h4 className="text-sm md:text-base text-center font-bold">
                  {x.title}
                </h4>

                {x.background_image && (
                  <img
                    src={x.background_image}
                    alt={x.id}
                    className="max-h-14 lg:max-h-32 rounded-lg object-cover"
                  />
                )}
                {!x.background_image && (
                  <p className="text-xs md:text-sm">{x.description}</p>
                )}

                <div className="flex flex-col items-center lg:flex-row lg:justify-between">
                  <div className="text-xs font-bold italic text-text-secondary">
                    {x.created_at &&
                      format(parseISO(x.created_at), "d MMM y HH:mm")}
                  </div>
                  <a
                    href={x.hyperlink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-4 py-1 text-xs font-bold text-white bg-primary rounded-2xl text-center"
                  >
                    Lihat Selengkapnya
                  </a>
                </div>
              </div>
            ))}

          {isError && (
            <div className="py-8 flex flex-col items-center space-y-4">
              <div className="text-text-secondary">
                <ChatIcon />
              </div>

              <p className="text-sm text-text-secondary">
                Belum ada announcement
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AnnouncementCard;
