import { useNavigate } from "react-router-dom";
import { HeaderContentRight } from "components";
import { currency } from "utils";

export default function HeaderContentLeft({
  balance,
  rate,
  rateSme,
  isLoading,
  isError,
  notifData,
  setIsModalOpen,
}) {
  const navigate = useNavigate();

  const withdraw = () => {
    navigate("/withdraw", { replace: true });
  };

  const rateEdit = () => {
    navigate("/rate/edit", { replace: true });
  };

  return (
    <div className="hidden xl:flex h-full flex-row items-center justify-between">
      <div className="flex justify-between w-4/12">
        <div className="flex items-center space-x-4">
          <span className="text-xs text-text-secondary">Balance</span>
          <span className="text-2xl font-bold">
            {!isLoading && currency(balance)}
            {isLoading && (
              <div className="w-24 animate-pulse overflow-hidden">
                <div className="w-full p-2 bg-gray-200 rounded"></div>
              </div>
            )}
          </span>
        </div>
        <div>
          <button
            className="px-4 py-[5px] rounded-xl border border-primary text-primary text-sm font-bold"
            onClick={() => withdraw()}
          >
            Tarik Dana
          </button>
        </div>
      </div>
      <div className="border border-border h-full"></div>
      <div className="flex justify-between items-center w-4/12">
        <div className="flex flex-col">
          <div className="flex items-center">
            <span
              className={`text-xs text-text-secondary w-24 ${
                isLoading ? "pb-2" : "pb-0"
              }`}
            >
              Rate Korporasi
            </span>
            <span className="font-bold">
              {!isLoading && currency(rate)}
              {isLoading && (
                <div className="pb-2 w-24 animate-pulse overflow-hidden">
                  <div className="w-full p-2 bg-gray-200 rounded"></div>
                </div>
              )}
            </span>
          </div>
          <div className="flex items-center">
            <span className="text-xs text-text-secondary w-24">Rate UMKM</span>
            <span className="font-bold">
              {!isLoading && currency(rateSme)}
              {isLoading && (
                <div className="w-24 animate-pulse overflow-hidden">
                  <div className="w-full p-2 bg-gray-200 rounded"></div>
                </div>
              )}
            </span>
          </div>
        </div>
        <div>
          <button
            className="px-4 py-[5px] rounded-xl border border-primary text-primary text-sm font-bold"
            onClick={() => rateEdit()}
          >
            Edit Rate
          </button>
        </div>
      </div>
      <div className="border border-border h-full"></div>
      <HeaderContentRight
        isError={isError}
        notifData={notifData}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
}
